<template>
  <Select v-model="current" :placeholder="placeholder" clearable filterable :disabled="disabled" :size="size" :multiple="multiple" @on-change="change" :loading="padding" filter-by-label>
    <Option v-for="(item,i) in panoramas" :key="i" :value="item.id" :label="`${item.name||''}`" />
  </Select>
</template>

<script>
import { getPanorama } from '@/libs/api/panorama'
export default {
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        type: String,
        value: { type: [String, Number, Array], default: '' },
        placeholder: String,
        disabled: Boolean,
        size: String,
        labelInValue: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        isArray: Boolean,
        departmentFilter: { type: Boolean, default: false },
        search: { type: Boolean, default: false },
        enterpriseId: String,
        disposalType: String,
        isAll: Boolean
    },
  data() {
    return {
      current: null,
      padding: false,
      panoramaAll: []
    }
  },
  
  computed: {
    panoramas() {
      const data = this.panoramaAll
      return this.state ? data.filter(item => this.state.includes(item.state)) : data
    },
  },
  watch: {
    value() {
      if (!this.padding) {
        this.setCurrent()
      }
    }
  },
  methods: {
      change(val) {
          if(!this.padding) {
              const { isArray, multiple, labelInValue } = this
              this.$emit('change', multiple ? isArray ? val : val.join(',') : val)
              this.$emit('on-change', labelInValue ? multiple ? this.panoramaAll.filter(item => val.includes(item.id)) : this.panoramaAll.find(item => item.id == val) : val)
          }
      },
      setCurrent() {
          const { value, multiple } = this
          this.current = multiple ? typeof value == 'string' ? value.split(',') : value : value
      },
      getData() {
          this.padding = true
          getPanorama({ current: 1, size: 1000 }).then(res => {
              this.padding = false
              this.panoramaAll = res.data.records
              this.$nextTick(() => this.setCurrent())
          })
      }
  },
  created() {
      if (!this.panoramas.length) {
          this.getData()
      }
  }
}
</script>

<style>

</style>