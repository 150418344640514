

export default {

    // 系统后台接口

    base: {

        // 登录
        login: '/huiyue-cloud-authserver/auth/login',

        // 退出
        logOut: '/huiyue-cloud-authserver/auth/logout',
        // logOut: '/yanxue-app/oauth/logout',

        // 判断当前用户是否登陆
        isLogin: '/huiyue-cloud-authserver/auth/isLogin',

        // 获取用户信息ID
        getUserInfoId: '/huiyue-cloud-authserver/auth/user/info',

        // 获取用户信息
        getUserInfoById: '/huiyue-cloud-authserver/userinfo/',

        // 获取机构信息
        getOrganizationInfo: '/huiyue-cloud-authserver/organization/organization/info/',

        // 获取授权码
        authorize: '/huiyue-cloud-authserver/oauth2/authorize',

        // 上传文件
        upload: '/minio-netdisk/file',

    },

    system: {

        // 研学点
        point: {
            // 

        },


        setup: {

            area: '/area/{id}',

        },


    },

}
