<template>
  <div>
    <template v-if="multiple && current && current.length">
        <Row type="flex" align="middle" v-for="(item, index) in current" :key="index">
            <Col span="22">
                <Tooltip :content="item" placement="top-start" transfer max-width="200" class="w-100">
                    <div class="ellipsis w-100">{{ item }}</div>
                </Tooltip>
            </Col>
            <Col span="2" v-if="!disabled"><Button type="error" size="small" icon="md-close" @click="delItem(index)"></Button></Col>
        </Row>
    </template>
    <Input v-model="current" placeholder="请选择数据" clearable class="m-b-5" v-if="!multiple && current" />
    <Upload
        :action="action"
        :on-success="successCall"
        :disabled="disabled"
        :accept="accept"
        :show-upload-list="false"
        :headers="uploadHeaders"
        :on-remove="delItem"
        :multiple="false"
        :on-error="error"
        :before-upload="before"
        :data="param"
        ref="upload"
        class="my-upload f-l"
        v-if="!disabled"
    >
        <Button type="success" :disabled="disabled">{{ uploadTxt }}</Button>
    </Upload>
    
    <Button type="info" @click="selectFile" class="m-l-5" v-if="!disabled">{{ text }}</Button>
    <Modal v-model="isModalShow" :title="text" width="1000" footer-hide>
        <iframe :ref="uuid" :src="src + '/open?type=geo&' + headers" class="netdiskModal-iframe"></iframe>
    </Modal>
    <Button type="primary" @click="openEpub()" class="m-l-5" v-if="course">制作课程</Button>
  </div>
</template>

<script>
import {util} from '@/libs'
import { fileUpLoadID } from '@/libs/api/base'
import store from '@/modules'


export default {
    model: { prop: 'value', event: 'change' },
    props: {
        value: [Array, String],
        text: { type: String, default: '地理数据' },
        placeholder: { type: String, default: '请选择...' },
        type: { type: String, default: 'text' },
        readonly: { type: Boolean, default: false },
        multiple: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        course: { type: Boolean, default: false },
        uploadTxt: { type: String, default: '上传文件' },
        // 上传api
        action: { type: String, default: process.env.VUE_APP_BASE_API + '/minio-netdisk/file/n', },
        // 单图多图，默认多图
        single: { type: Boolean, default: false },
        // 上传文件类型
        accept: { type: String, default: '' },
        // 上传参数
        param: { type: Object, default: () => { return { folderId: 2173, userId: store.state.user.userId } } },
        // 上传成功回调
        success: { type: Function },
        // 上传到指定文件夹下
        folderId: { type: Number, default: 2173 },
    },
    data() {
        return {
            current: null,
            uuid: util.uuid(),
            isModalShow: false,
            src: process.env.VUE_APP_BASE_PAN,
        }
    },
    computed: {
        headers() {
            let headers = {}, headerStr = ''
            for(let i in localStorage) {
                if(i.split('_')[0] === 'token') {
                    headers[i] = localStorage[i]
                }
            }
            Object.keys(headers).forEach(item => {
                if(headerStr === '') {
                    headerStr = `${item}=${headers[item]}`
                } else {
                    headerStr = `${headerStr}&${item}=${headers[item]}` 
                }
            })
            return headerStr
        },
        uploadHeaders() {
            let headers = {
                'X-Requested-With': 'XMLHttpRequest' 
            }
            for(let i in localStorage) {
                if(i.split('_')[0] === 'token') {
                    headers[i] = localStorage[i]
                }
            }
            return headers
        }
    },
    watch: {
        value(value) {
            this.current = value
        }
    },
    methods: {

        // 打开epub
        openEpub() {
            window.open(`${window.location.origin}/researchCourse`)
        },

        // 上传之前
        before(file) {
            if (!this.disabled) {
                if (this.single) {
                    this.$refs.upload.clearFiles()
                }
            } else {
                return false
            }
        },

        // 上传成功回调
        successCall(payload, file, list) {
            this.setCurrent(`${process.env.VUE_APP_BASE_API}/minio-netdisk/file/${payload.data}`)
            if (this.success) {
                this.success(payload, file, list)
            }
        },
        // 文件读取或上传失败
        error(error) {
            this.$Notice.error({ title: '图片解析或上传失败！' })
        },


        delItem(index) {
            this.current.splice(index, 1)
            this.$emit('change', this.current)
            this.$forceUpdate()
        },
        setCurrent(val) {
            if(this.multiple) {
                this.current ? this.current.push(val) : this.current = [ val ]
            } else {
                this.current = val
            }
            this.isModalShow = false
            this.$emit("change", this.current)
        },

        // 上传数据
        uploadFile() {
            const formData = new FormData()
            formData.append("file", blob);
            formData.append("folderId", this.folderId);
            formData.append("userId", this.$store.state.user.userId);
            fileUpLoadID(formData).then(res => {
                if(res.code="HA0200"){
                    this.$Message.success("文件上传成功！")
                    this.setCurrent(`${process.env.VUE_APP_BASE_API}/minio-netdisk/file/${res.data}`)
                }
            })
        },

        // 选择地理数据
        selectFile() {
            // this.src = 'http://pan.xingyaedu.cn/open?type=geo&' + this.headers
            this.isModalShow = true
        }
    },
    mounted() {
        /**
         * 监听网盘iframe传来的消息，并做相应处理
         * 消息内容：{action: "close"}或{action: "selectFile", fileUrl: "http://xxx.com/file"}
         */
        window.addEventListener('message', (event) => {

            if (event.origin !== process.env.VUE_APP_BASE_PAN) {
                return;
            }
            let netdiskEventData = JSON.parse(event.data);
            // console.log("接收到来自网盘的消息：", netdiskEventData, this.isModalShow)
            if (netdiskEventData.action === "close" && this.isModalShow) {
                // 如果网盘传来关闭网盘的消息
                this.isModalShow = false
            } else if (netdiskEventData.action === "selectFile" && this.isModalShow) {
                // 如果网盘传来选择一个文件的消息
                let fileUrl = netdiskEventData.fileUrl;
                this.setCurrent(fileUrl)
                
            }
        }, false);
    }
}
</script>

<style lang="less" scoped>
.my-upload {
    margin: 0;
}
.netdiskModal-iframe {
    width: 100%;
    height: 500px;
    border: solid 1px #eee;
}
</style>