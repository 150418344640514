<template>
  <div class="map-wrap">
    <div ref="map" class="map-box" :style="style"></div>
    <div class="map-control">
        <Button type="info" custom-icon="iconfont icon-hand-pointer-o" @click="clearTool()" />
        <Button type="info" custom-icon="iconfont icon-position" @click="setMarkerTool()" v-if="point" />
        <Button type="info" custom-icon="iconfont icon-guijiluxian" @click="setPolylineTool()" v-if="line" />
        <Button type="success" size="small" class="m-l-5" @click="addLayer()" v-if="isLayer">添加卫星图层</Button>
        <Button type="warning" size="small" class="m-l-5" @click="removeLayer()" v-else>删除卫星图层</Button>
        
    </div>
    <div class="search" v-if="point">
        <Input v-model="value" placeholder="地图检索" search enter-button @on-search="handlerSearch" />
    </div>
    <div class="m-t-10" v-if="line && polyline">
        <Button type="success" size="small" @click="editLine()">编辑</Button>
        <Button type="success" size="small" class="m-l-5" @click="getLine()" :disabled="!isEdit">编辑完成</Button>
        <Button type="primary" size="small" class="m-l-5" @click="saveData()">保存GPX</Button>
        <Tag color="success" class="m-l-10" v-if="data.lineGpx">GPX文件：{{ data.lineGpx + '.gpx' }}</Tag>
        <Tag color="warning" class="m-l-10" v-else>请先确认路线，再保存GPX文件！</Tag>
    </div>
  </div>
</template>

<script>
import { util } from '@/libs'
import MapBase from '@/libs/graph/MapBase'
import { fileUpLoadID, downloadFile } from '@/libs/api/base'
export default {
    // model: { prop: 'value', event: 'change' },
    props: {
        point: { type: Boolean, default: true },
        line: { type: Boolean, default: false },
        data: { type: Object, required: true },
        zoom: { type: Number, default: 11 },
        setAddress: { type: Boolean, default: true },
        address: { type: String, default: 'address' },
        setPoint: { type: String, default: 'pointCoord' },
        setOrg: { type: String, default: 'pointOrg' },
        setAdd: { type: String, default: 'pointAdd' },
        coords: { type: String, default: 'coords' },
        disabled: { type: Boolean, default: false },
        height: { type: Number, default: 400 }
    },
    data() {
        return {
            uuid: util.uuid(),
            map: null,
            markerTool: null,
            handler: null,
            value: null,
            polyline: null,
            markers: [],
            linePoints: [],
            linePath: [],
            isEdit: false,
            isLayer: true,
            imgLayer: null,
        }
    },
    computed: {
        style() {
            return {
                height: this.height + 'px'
            }
        }
    },
    methods: {
        // 清除标注工具
        clearTool() {
            this.markerTool && this.markerTool.close()
            this.polylineTool && this.polylineTool.close()
        },
        // 创建标注工具对象
        setMarkerTool() {
            if (this.marker) {
                this.map.removeOverLay(this.marker)
            }
            const markerTool = new T.MarkTool(this.map, {follow: true});
            markerTool.open()
            const _this = this
            this.markerTool = markerTool
            markerTool.addEventListener('mouseup', (e) => {
                // Object.assign(_this.data, e.currentLnglat)/
                _this.setViewMarker(e.currentLnglat)
                markerTool.clear()
                markerTool.close()
            })
        },
        // 创建线工具
        setPolylineTool() {
            if(this.polyline) {
                this.map.removeOverLay(this.polyline)
                this.clearLinePoints()
            }
            const polylineTool = new T.PolylineTool(this.map)
            polylineTool.open()
            this.polylineTool = polylineTool
            polylineTool.addEventListener('draw', (e) => {
                this.setViewLine(e.currentLnglats)
                polylineTool.clear()
                polylineTool.close()
            })
            polylineTool.addEventListener('addpoint', (e) => {
                // 给每个点添加时间
                e.currentLnglats[e.currentLnglats.length - 1].time = this.$moment().utc().format()
                e.currentLnglats[e.currentLnglats.length - 1].ele = '50'
            })
        },

        // 获取点的经纬度
        genPoint(pt) {
            return new T.LngLat(pt.lng || pt[0], pt.lat || pt[1])
        },
        // 获取地址
        genAddress() {
            const _this = this
            const position = this.marker && this.marker.getLngLat()
            if(position) {
                const geoc = new T.Geocoder()
                geoc.getLocation(position, res => {
                    if(res.getAddressComponent()) {
                        const { addressComponent: { nation, province, city, county, county_code } } = res.getAddressComponent()
                        Object.assign(_this.data, { [this.setAdd]: `${nation},${province},${city},${county}`, [this.setOrg]: { ..._this.data[this.setOrg], division: county_code } })
                        _this.$set(_this.data, _this.address, res.getAddress())
                    }
                })
            }
        },
        // 检索
        handlerSearch() {
            this.clearAll()
            this.search = new T.LocalSearch(this.map, { onSearchComplete: (res) => {
                const _this = this
                if(res.pois.length) {
                    res.pois.map(item => {
                        let lonlat = item.lonlat.split(' ')
                        let pt = _this.genPoint(lonlat)
                        let marker = new T.Marker(pt)
                        _this.map.addOverLay(marker)
                        _this.markers.push(marker)
                        _this.map.centerAndZoom(pt, 16)
                        let infoWindow = new T.InfoWindow(`名称：${item.name}<br />地址：${item.address}`, { autoPan: true })
                        marker.addEventListener('click', () => {
                            marker.openInfoWindow(infoWindow)
                        })

                    })
                }
            }})
            this.search.search(this.value)
            
        },
        // 清除搜索图层
        clearAll() {
            if(this.markers && this.markers.length) {
                this.markers.map(item => this.map.removeOverLay(item))
                this.markers = []
            }
        },
        
        // 点图标显示
        setViewMarker(pt) {
            this.clearAll()
            const position = this.genPoint({ lng: pt.lng, lat: pt.lat })
            if (this.marker) {
                this.map.removeOverLay(this.marker)
            }
            this.marker = new T.Marker(position);
            this.map.addOverLay(this.marker)
            if(this.setAddress) {
                this.genAddress()
            }

            Object.assign(this.data, { [this.setPoint]: { ...this.data[this.setPoint], lon: pt.lng, lat: pt.lat } })

            this.marker.addEventListener('dblclick', (e) => {
                this.marker.enableDragging()
            })
            this.marker.addEventListener('dragend', (e) => {
                this.setViewMarker(e.lnglat)
                this.marker.disableDragging()
            })
            
            this.map.centerAndZoom(position, 16)
        },
        // 线显示
        setViewLine(coords) {
            let marker = null
            const { linePath, linePoints, tool } = this
            this.polyline && this.map.removeOverLay(this.polyline)
            if(coords && coords.length) {
                const pts = coords.map((item, index) => {
                    let pt = this.genPoint(item)
                    Object.assign(pt, item)
                    if(index == 0) {
                        marker = tool.marker(pt, tool.icon('start_icon', 20))
                    }  else if (index == (coords.length -1)) {
                        marker = tool.marker(pt, tool.icon('end_icon', 20))
                    } else {
                        marker = tool.marker(pt, tool.icon('position', 20))
                    }
                    linePoints.push(marker)
                    linePath.push(Object.assign(item, { lon: item.lng }))
                    return pt
                    
                })
                this.polyline = this.tool.polyline(pts, { color: '#1e88e5', weight: 3, opacity: 1 })
                this.map.setViewport(pts)
            }
        },
        // 清除线上的点图标
        clearLinePoints() {
            this.linePoints.map(item => this.map.removeOverLay(item))
            this.linePoints = []
            this.linePath = []
        },
        // 编辑线
        editLine() {
            this.isEdit = true
            this.polyline.enableEdit()
            this.clearLinePoints()
        },
        // 编辑完成
        getLine() {
            this.isEdit = false
            this.setViewLine(this.polyline.getLngLats())
            this.polyline.disableEdit()
            
        },
        // 保存GPX文件，并上传服务器
        saveData() {
            if(!this.linePath.length) {
                this.$Message.warning("请先添加研学路线！")
                return
            }
            if(!this.data.lineName) {
                this.$Message.warning("请先添加研学路线名称！")
                return 
            }
            const GPX_text = util.creatGPX(this.linePath, this.data.lineName)
            const blob = new Blob([GPX_text], {type: "text/xml;charset=utf-8"})
            const gpx_file = new File([blob], `${this.data.lineName}.gpx`, {type: "text/xml;charset=utf-8"})
            const formData = new FormData()
            formData.append("file", gpx_file);
            formData.append("folderId", 2171);
            formData.append("userId", this.$store.state.user.userId);
            fileUpLoadID(formData).then(res => {
                if(res.code="HA0200"){
                    this.$Message.success("GPX文件保存成功！")
                    this.data['lineGpx'] = res.data
                }
            })

        },
        // 获取gpx文件返回数据
        getData() {
            let fileId = null
            if(this.data.lineGpx.includes('/minio-netdisk/file/')) {
                fileId = this.data.lineGpx.split('/')[this.data.lineGpx.split('/').length - 1];
            } else if (this.data.lineGpx.includes('.gpx')) {
                fileId = this.data.lineGpx.replace('.gpx', '')
            } else 
                fileId = this.data.lineGpx
            
            downloadFile({ fileId }).then(res => {
                let coords = util.getGPXNode(res)
                this.setViewLine(coords)
            })
        },
        init(data = {}) {
            if(data[this.setPoint]) {
                let { lon, lat } = data[this.setPoint]
                if(lon && lat) {
                    this.setViewMarker({lng: lon, lat})  
                }
            }
            if(data[this.coords]) {
                this.setViewLine(data[this.coords])
            }
            if(data.lineGpx) {
                this.getData()
            }
        },
        // 添加卫星图层
        addLayer() {
            this.isLayer = !this.isLayer
            // 加载天地图卫星地图
            const imageURL = 'https://t0.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=32943f8a8cee5d0aba41ca81477690b2'
            this.imgLayer = new T.TileLayer(imageURL, {minZoom: 1, maxZoom: 18});

            //添加卫星图层到地图中
            this.map.addLayer(this.imgLayer);
        },
        // 清除卫星图层
        removeLayer() {
            this.isLayer = !this.isLayer
            this.map.removeLayer(this.imgLayer)
            this.imgLayer = null
        }

    },
    mounted() {
        util.initTmap().then(() => {
            const map = new T.Map(this.$refs.map, {})
            map.centerAndZoom(new T.LngLat(113.619320,34.747790), this.zoom);
            map.enableScrollWheelZoom();
    
            this.tool = new MapBase(map)
            this.map = map
            this.init(this.data)
            // this.$watch('data', this.init())
        })
        
    }

}
</script>

<style lang="less">
.map-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    .map-box {
        width: 100%;
        height: 100%;
    }
    .map-control {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 410;
        border-radius: 5px;
    }
    .search {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 410;
    }
}
</style>