import axios from 'axios'
import iView from 'view-design'
import router from '../router'
import store from '@/modules'

let loginUrl = process.env.VUE_APP_BASE_LOGIN + '/?redirect_uri=' + location.href

if (localStorage.getItem('url')) {
  loginUrl = decodeURIComponent(localStorage.getItem('url'))
}

let loadings = null
/**
 * 创建axios实例
 */
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = 基础 url + 请求 url
  // withCredentials: true, // 跨域请求时发送cookies
  timeout: 500000 // 请求超时
})
// 请求拦截器
service.interceptors.request.use(
  config => {
    if (config.method === 'get') {
      store.commit('ivew/set_isLoading', true)
      // loadings = Loading.service({
      //   lock: true,
      //   text: '拼命加载中...',
      //   background: 'rgba(255,255,255,0.7)'
      // })
    }
    // 在发送请求之前做些什么
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    for (let i in localStorage) {
      if (i.split('_')[0] === 'token') {
        config.headers[i] = localStorage[i]
      }
    }
    if (config.ContentType) {
      config.headers['Content-Type'] = config.ContentType
    }
    return config
  },
  error => {
    // 处理请求错误
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  /**
   * 如果您想获取http信息，如标头或状态
   * 请返回  response => response
   */

  /**
   * 通过自定义代码确定请求状态
   * 这里只是一个例子
   * 您还可以通过HTTP状态码来判断状态
   */
  response => {
    store.commit('ivew/set_isLoading', false)
    if (loadings) {
      loadings.close()
    }
    // console.log("response.data.code",response.data.code, response)
    // if (response.data.code === 401) {
    //   location.href = decodeURIComponent(loginUrl)
    // }
    if (response.data.code === 500) {
      store.commit('user/USER_INFO_LOGINOUT')
      router.push('/login')
    }
    // if (response.data.code === 403) {
    //   let url = '/huiyue-cloud-authserver/oauth2/authorize?response_type=code&client_id=1004&redirect_uri=' + location.href + '&scope=userinfo'
    //   service({
    //     url: url,
    //     method: 'get'
    //   }).then(res => {
    //     let urls = '/minio-netdisk/oauth/codeLogin?code=' + res.data
    //     service({
    //       url: urls,
    //       method: 'get'
    //     }).then(res => {
    //       const { code, data, msg } = res
    //       if (code === 200) {
    //         localStorage.setItem(data.tokenName, data.tokenValue)
    //         setTimeout(() => {
    //           location.reload()
    //           this.loading = false
    //         }, 500)
    //       }
    //     })
    //   })
    // }
    // if (response.data.data && response.data.data.code === 403) {
    //   getMarket()
    // }
    if (response.request.responseURL.indexOf('oauth/token')) {
      return response.data
    } else {
      const res = response.data
      return res
    }
  },
  error => {
    // console.log("error", error, error.response)
    store.commit('ivew/set_isLoading', false)
    if (loadings) {
      loadings.close()
    }
    // 发送验证码返回状态码判断
    if(error && error.response  && error.response.config.params && error.response.config.params.apiDesc == "1") {
      if(error.response.status === 422) {
        iView.Message.warning("验证码输入错误")
      }
      if(error.response.status === 409) {
        iView.Message.warning("账号冲突，请确认该账号是否已存在")
      }
      if(error.response.status === 429) {
        iView.Message.warning("触发短信发送阈值")
      }
      if(error.response.status === 500) {
        iView.Message.warning("短信发送错误")
      }
      if(error.response.status === 404) {
        iView.Message.warning("Not Found")
      }

    }
    // end

    if ((error.response && error.response.status === 500) || !error.response) {
      store.commit('user/USER_INFO_LOGINOUT')
      router.push('/login')
    }
    return Promise.reject(error)
  }
)
function getMarket() {
  let url = '/huiyue-cloud-authserver/oauth2/authorize?response_type=code&client_id=1004&redirect_uri=' + location.href + '&scope=userinfo'
  service({
    url: url,
    method: 'get'
  }).then(res => {
    let urls = '/huiyue-cloud-market/oauth/codeLogin?code=' + res.data
    service({
      url: urls,
      method: 'get'
    }).then(res1 => {
      const { code, data, msg } = res1
      if (code === 200) {
        localStorage.setItem(data.tokenName, data.tokenValue)
        setTimeout(() => {
          location.reload()
          this.loading = false
        }, 500)
      }
    })
  })
}
export default service
