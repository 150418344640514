<template>
  <div class="photo-view">
    <Row :gutter="10" type="flex" align="middle" ref="video">
      <Col
        class="photo-view-item flex-center"
        :style="{ width: width + 'px', height: height + 'px' }"
        v-for="(item, i) in imgs"
        :key="i"
      >
        <video :src="item" @click="showView" controls class="h-100 c-h"></video>
        <!-- <Icon class="c-h" type="ios-resize"  /> -->
      </Col>
    </Row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Array],
    },
    width: { type: Number, default: 150 },
    height: { type: Number, default: 100 },
  },
  data() {
    return {};
  },
  computed: {
    imgs() {
      let val = [];
      if (this.value) {
        if (typeof this.value == "string") {
          try {
            val = JSON.parse(this.value);
          } catch (e) {
            val = this.value.split(",");
          }
        } else {
          val = this.value;
        }
      }
      return val.filter((item) => item);
    },
  },
  methods: {
    showView() {
      
    }
  },
};
</script>

<style lang="less">
.photo-view {
    .photo-view-item {
        border-radius: 5px;
        border: 1px solid rgba(126, 126, 126, 0.2);
        position: relative;
        margin: 0px 5px 5px 0px;
        video {
            max-width: 100%;
            max-height: 100%;
        }
        i {
            position: absolute;
            right: 5px;
            top: 5px;
            display: none;
        }
    }
    .photo-view-item:hover i {
        display: block;
    }
}
</style>
