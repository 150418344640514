export default [
    {
        path: '/',
        meta: { title: '首页', login: true },
        redirect: '/dataV/index'
    },
    {
        path: '/login',
        meta: { title: '登录' },
        component: () => import(`@/views/frame/Login.vue`),
    },
    {
        path: '/register',
        meta: { title: '注册' },
        component: () => import(`@/views/frame/register.vue`),
    },
    {
        path: '/researchCourse',
        meta: { title: '制作课程', login: true },
        component: () => import(`@/views/frame/researchCourse.vue`),
    },
    {
        path: '/researchCourseView/:epubId',
        meta: { title: '查看课程', login: true },
        component: () => import(`@/views/frame/researchCourseView.vue`),
    },
    {
        path: '/activityShare/:base',
        meta: { title: '活动分享', login: false },
        component: () => import(`@/views/frame/activityShare.vue`),
    },
    {
        path: '/notFound',
        meta: { title: '403', login: false },
        component: () => import(`@/views/frame/notFound.vue`),
    }
    // {
    //     path: '/demo',
    //     meta: { title: 'test', login: true },
    //     component: () => import(`@/views/dataV/demo.vue`)
    // }
    
]