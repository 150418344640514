<template>
    <DatePicker class="w-100" :value="current" :type="type" :format="format" :placeholder="placeholder" :size="size" :options="options" @on-change="handlerSelect" @on-ok="ok" :disabled="disabled" :clearable="clearable" :transfer="transfer" :show-week-numbers="showWeekNumbers"></DatePicker>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        type: { type: String, default: 'date' },
        placeholder: { type: String, default: '日期' },
        format: { type: String },
        value: { type: String },
        disabled: { type: Boolean, default: false },
        clearable: { type: Boolean, default: true },
        options: { type: Object },
        size: String,
        transfer:Boolean,
        showWeekNumbers: { type: Boolean, default: false },
    },
    computed: {
        current() {
            return this.value ? Date.new(this.value) : null
        }
    },
    methods: {
        handlerSelect(val) {
            this.$emit('change', val ? Date.format(val, this.format) : '')
        },
        ok() {
            this.$emit('on-ok', this.current ? Date.format(this.current, this.format) : '')
        }
    }
}
</script>
