<template>
    <div>
        <Card class="upload" dis-hover :padding="5" v-for="(item,index) in uploads" :key="index">
            <template v-if="item.status === 'finished'">
                <div class="upload-image-item">
                    <img :src="renderImg(item.url)">
                </div>
                <Button @click="handleRemove(item,index)" type="error" size="small" v-show="!disabled">删除</Button>
            </template>
            <Progress v-if="item.showProgress" :percent="item.percentage"></Progress>
        </Card>
        <Upload :class="uploads.length?'m-t-5':''" :action="action" :on-success="successCall" :type="type" :disabled="disabled" :accept="accept" :show-upload-list="false" :default-file-list="uploads" :headers="headers" :on-remove="handleRemove" :multiple="false" :on-error="error" :before-upload="before" :data="param" ref="upload">
            <div v-if="type == 'select'">
                <Button type="info" :disabled="disabled">选择图片</Button>
            </div>
            <div class="p-20" v-else>
                <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                <p>拖拽文件</p>
            </div>
        </Upload>
    </div>
</template>

<script>
import { _api, util } from '@/libs'
export default {
    name: 'PhotoUpload',
    model: { prop: 'img', event: 'change' },
    props: {
        // 双向绑定字段(single==true返回ossPath字符串，single==false返回字符串数组)
        img: { type: [String, Array] },
        // 禁用组件
        disabled: Boolean,
        // 单图多图，默认单图
        single: { type: Boolean, default: true },
        // 上传方式 ('select':图片选择框,'drag':拖拽)
        type: { type: String, default: 'select' },
        // 上传api
        action: { type: String, default: process.env.VUE_APP_BASE_API + '/minio-netdisk/file/n', },
        // 上传文件类型
        accept: { type: String, default: 'image/jpg,image/png,image/jpeg,image/bmp' },
        // 上传参数
        param: { type: Object, default: () => { return { folderId: 2173 } } },
        // 是否压缩，默认是
        resize: { type: Boolean, default: true },
        // 压缩参数
        resizeParam: { type: Object, default: () => { return { maxWidth: 1200, maxHeight: 1200, quality: 0.9 } } },
        // 上传成功回调
        success: { type: Function }
    },
    data() {
        return {
            uuid: util.uuid(),
        }
    },
    computed: {
        uploads: {
            get() {
                let arr = []
                if (this.single) {
                    if (this.img) {
                        arr.push({ name: this.img, url: this.img, status: 'finished' })
                    }
                } else {
                    if (this.img) {
                        let imgs = []
                        try {
                            imgs = JSON.parse(this.img)
                        }
                        catch {
                            imgs = this.img.split(',')
                        }
                        arr = imgs.map(item => {
                            return { name: item, url: item, status: 'finished' }
                        })
                    }
                }
                return arr
            },
            set(val) {
                if (this.single) {
                    const last = val[val.length - 1]
                    this.$emit('change', last && last.url)
                } else {
                    this.$emit('change', this.renderImgs(val))
                }
            }
        },
        headers() {
            let headers = {
                'X-Requested-With': 'XMLHttpRequest' 
            }
            for(let i in localStorage) {
                if(i.split('_')[0] === 'token') {
                    headers[i] = localStorage[i]
                }
            }
            return headers
        }
    },
    methods: {
        // 上传之前
        before(file) {
            if (!this.disabled) {
                if (this.single) {
                    this.$refs.upload.clearFiles()
                }
            } else {
                return false
            }
            // const reader = new FileReader();
            // reader.readAsDataURL(file);
            // reader.onload = (e) => {
            //     console.log("e:", e)
            //     this.img = e.target.result
            //     // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
            //     // const code = e.target.result;
            //     // this.imgBase64 = code;  
            // };
            
            // return false
        },
        // 上传成功回调
        successCall(payload, file, list) {
            console.log("payload, file, list:", payload, file, list)
            Object.assign(file, { url: payload.data })
            if (this.success) {
                this.success(payload, file, list)
            }
            this.uploads = list
        },
        // 删除图片
        handleRemove(i) {
            if (this.single) {
                this.uploads = []
                this.$emit('change', null)
            } else {
                this.uploads.splice(i, 1)
                this.$emit('change', this.renderImgs(this.uploads))
            }
        },
        // 文件读取或上传失败
        error(error) {
            this.$Notice.error({ title: '图片解析或上传失败！' })
        },
        renderImgs(uploads) {
            return JSON.stringify(uploads.filter(item => item.status == 'finished').map(item => item.url))
        },
        renderImg(img) {
            return img
        }
    },
    created() {
        // 获取网盘token
        let tokenNetdisk = localStorage.getItem('token_netdisk')
        if(!tokenNetdisk) {
            this.$store.dispatch('user/getAuthCode', { clientId: '1001', redirectUri: 'http://pan.xingyaedu.cn' }).then(res => {
                if(res.code == 200) {
                    this.$store.dispatch('user/getTokenNetdisk', { authCode: res.data })
                }
            })
        }
    }
}
</script>

<style lang="less">
.upload {
    display: inline-block;
    width: auto;
    margin: 5px;
    .upload-image-item {
        width: 120px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
</style>
