<template lang="html">
    <div class="editor">
      <div ref="toolbar" class="toolbar" ></div>
      <div ref="editor" class="text"></div>
    </div>
  </template>
  
  <script>
  import { fileUpLoadID } from '@/libs/api/base'
  import Editor from 'wangeditor'
  import { _api, util } from '@/libs'
  export default {
      props: {
          value: { type: String, default: '' },
      },
      name: 'WangEditor',
      data() {
          return {
              editor: null,
          }
      },
      mounted() {
          this.seteditor()
      },
      methods: {
          seteditor() {
              this.editor = new Editor(this.$refs.toolbar, this.$refs.editor)
              this.editor.customConfig.uploadImgShowBase64 = false // base 64 存储图片，如果这个参数设置为true的话，就不用配置服务器端上传地址
              this.editor.customConfig.showLinkImg = false  //   禁止上传网络图片
              this.editor.customConfig.uploadFileName = 'file' // 后端接受上传文件的参数名
              this.editor.customConfig.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
              // this.editor.customConfig.uploadImgMaxLength = this.num 
              this.editor.customConfig.uploadImgTimeout = 60000 // 设置超时时间
              this.editor.customConfig.customAlert = (info) => {
                  this.$Modal.confirm({ title: '提示', content: info, })
              }
  
              // 配置菜单
              this.editor.customConfig.menus = ['head', 'bold', 'fontSize', 'fontName', 'italic', 'underline', 'strikeThrough', 'foreColor', 'backColor', 'link', 'list', 'justify', 'quote', 'emoticon', 'image', 'table', 'video', 'code', 'undo', 'redo', 'fullscreen']
  
              this.editor.customConfig.uploadImgHooks = {
                  fail: (xhr, editor, result) => {
                      // 插入图片失败回调
                      console.log(xhr, editor, result)
                  },
                  success: (xhr, editor, result) => {
                      // 图片上传成功回调
                      console.log(xhr, editor)
                  },
                  timeout: (xhr, editor) => {
                      // 网络超时的回调
                      console.log(xhr, editor)
                  },
                  error: (xhr, editor) => {
                      console.log(xhr, editor)
                      // 图片上传错误的回调
                  }
              }
              // 图片上传到服务器
              this.editor.customConfig.customUploadImg = (files, insert) => {
                  var formData = new FormData();
                  for (var i = 0; i < files.length; i++) {
                      formData.append("file", files[i], files[i].name);
                  }
                  formData.append("folderId", 2173);
                  formData.append("userId", this.$store.state.user.userId);
                // 上传至服务器
                  fileUpLoadID(formData).then(res => {
                      if (res.code="HA0200") {
                        insert(process.env.VUE_APP_BASE_API + '/minio-netdisk/file/' + res.data);
                      } else {
                        this.$Message.error(res.msg)
                        return;
                      }
                  })
              };
  
              // 创建富文本编辑器
              this.editor.create()
  
          },
          setHtml(html) {
              this.editor.txt.html(html)
          },
          getHtml() {
              return this.editor.txt.html()
          }
      }
  }
  </script>
  
  <style lang="less" scoped>
  @import "./../../style/re-iview.less";
  .editor {
      width: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 0;
      .toolbar {
          border: 1px solid @system-border-color;
          border-bottom: none;
      }
      .text {
          border: 1px solid @system-border-color;
          min-height: 300px;
      }
      .disabled {
          color: #ccc;
          background: @system-border-color;
      }
  }
  </style>
  <style lang="less">
  .w-e-text {
      ol,
      ul {
          margin-left: 40px;
      }
  }
  </style>