import _api from '../libs/_api'
import dict from './dict'
import configMenus from './configMenu'
let isHidden = true

const config = {

    page: {
        title: '研学途迹',
        copyright: '技术服务：郑州兴雅教育科技有限公司',

        dataVMenus: [
            {
                title: '首页',
                path: 'index',
                fullPath: '/dataV/index',
                icon: '',
                type: 2,
                isHidden: isHidden,
                children: [
                    {
                        title: '研学点',
                        path: 'point',
                        fullPath: '/dataV/index/point',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden,
                    },
                    {
                        title: '研学路线',
                        path: 'line',
                        fullPath: '/dataV/index/line',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden,
                    },
                    {
                        title: '研学漫游',
                        path: 'roam',
                        fullPath: '/dataV/index/roam',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden,
                    },
                ]
            }
        ],
        systemMenus: [
            {
                title: '数字地球',
                path: 'digitalEarth',
                fullPath: '/system/digitalEarth',
                icon: '',
                type: 2,
                isHidden: isHidden,
                children: []
            },
            {
                title: '智能控制',
                path: 'intelligentControl',
                fullPath: '/system/intelligentControl',
                icon: '',
                type: 2,
                isHidden: isHidden,
                children: []
            },
            {
                title: '研学实践',
                path: 'researchPractice',
                fullPath: '/system/researchPractice',
                icon: '',
                type: 2,
                isHidden: isHidden,
                children: [
                    {
                        title: '研学点',
                        path: 'positionPoint',
                        fullPath: '/system/researchPractice/positionPoint',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden,
                    },
                    {
                        title: '研学点详情',
                        path: 'pointDetail',
                        fullPath: '/system/researchPractice/pointDetail',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden,
                    },
                ]
            },
            
        ],
        configMenus,
    },

    createMenus(user) {
        const { dataVMenus, systemMenus, configMenus } = config.page
        return {
            dataV: dataVMenus,
            system: systemMenus,
            config: configMenus
        }
    },


    genChildrenRoute(routers, menus, _path) {
        menus.map(item => {
            const { title, path, children, isHidden } = item
            const fullpath = `${_path}/${path}`
            // if (children && children.length) {
            //     config.genChildrenRoute(routers, item.children, fullpath)
            // } else {
            //     routers.push({ path: fullpath, meta: { title: title }, component: () => import(`@/views${fullpath}.vue`) })
            // }
            if (children && children.length) {
                config.genChildrenRoute(routers, item.children, fullpath)
            }
            if(item.type == 2) {
                routers.push({ path: fullpath, meta: { title: title, isHidden, login: true }, component: () => import(`@/views${fullpath}.vue`) })
            }
        })
    },




    createRouters(menu) {
        const dataV = [], system = [], _config = []
        config.genChildrenRoute(dataV, menu.dataV, '/dataV')
        config.genChildrenRoute(system, menu.system, '/system')
        config.genChildrenRoute(_config, menu.config, '/config')
        const dataVPath = dataV.find(node => node.meta.isHidden)
        const systemPath = system.find(node => node.meta.isHidden)
        const configPath = _config.find(node => node.meta.isHidden)
        return [{
            path: '/dataV',
            meta: { title: '可视化平台', login: true },
            component: () => import(`@/views/frame/frameDataV.vue`),
            children: dataV,
            index: dataVPath && dataVPath.path
        }, {
            path: '/system',
            meta: { title: '系统后台', login: true },
            component: () => import(`@/views/frame/frameSystem.vue`),
            children: system,
            index: systemPath && systemPath.path
        }, {
            path: '/config',
            meta: { title: '系统后台', login: true },
            component: () => import(`@/views/frame/frameConfig.vue`),
            children: _config,
            index: configPath && configPath.path
        }]
    },

    tMapKey: '4b9163fdcca4dc7b381c53138ea73d47',
    bingMapKey: 'AiQfD7DxzdndrKIZ1ejKiQAjDGi55NB9yz2_tefvcquGcEOm-l5xTj8PP7CcXp2M',

    // 公司用的token
    cesiumToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkMTI1ZDE3Ni03NTY1LTQ2ZDMtODMzNC1kNTU4OTYyZDRiM2IiLCJpZCI6NzU1NjYsImlhdCI6MTY4MTM1NDMyNn0.zq9hKHe8hZ6REmW1goXJ6_oCAKd33Qi2RhOSacDqTJg',
    pattern: {
        // 邮件
        email: /^\+?[a-z0-9](([-+.]|[_]+)?[a-z0-9]+)*@([a-z0-9]+(\.|\-))+[a-z]{2,6}$/,
        // 密码; 1 可以全数字，2 可以全字母，3 可以全特殊字符(~!@#$%^&*.)，4 三种的组合，5 可以是任意两种的组合，6 长度6-22
        pass: /^[\@A-Za-z0-9\!\#\$\%\^\&\*\.\~]{6,22}$/,
        // 验证码
        sms: /^\d{4}$/,
        // 身份证
        card: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
        // 手机号（支持手机号码，3-4位区号，7-8位直播号码，1－4位分机号）
        phone: /^(\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$/,
        // 全数字
        number: /^[0-9]*$/,
        // 数字或小数
        number2: /^[0-9]+\.{0,1}[0-9]{0,2}$/,
        // 用户名
        regName: /^[a-zA-z][a-zA-Z0-9_]{3,16}$/,
        // 手机号
        // mobile: /^1[34578]\d{9}$/,
        mobile: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,

    }
}

export { config, dict }
