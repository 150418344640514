import { request } from '@/libs'

/**
 * 研学全景图
 */

// 分页获取全景图列表
export function getPanorama(params) {
  return request.post('/yanxue-app/research/qjt/getbypage', params)
}

// 获取全景图详情
export function getPanoramaById(params) {
  return request.get(`/yanxue-app/research/qjt/${params.id}`, { params })
}

// 添加全景图
export function addPanorama(params) {
  return request.post('/yanxue-app/research/qjt', params)
}

// 修改全景图
export function updatePanorama(params) {
  return request.put('/yanxue-app/research/qjt', params)
}

// 删除全景图
export function deletePanorama(params) {
  return request.delete(`/yanxue-app/research/qjt/${params.id}`, { params })
}