import Vue from 'vue'

// import VueCountup from 'vue-countupjs'

const components = [
    'GeoFile',
    'Navbar',
    'UploadPhoto',

    'InfoWindow',
    'Map',
    'MyDataPicker',
    'PhotoUpload',
    'PhotoView',
    'RangeDataPicker',
    'SelectDict',
    'SelectLine',
    'SelectPanorama',
    'SelectPoint',
    'Vcell',
    'Vgroup',
    'VideoView',
    'VMenuItem',
    'WangEditor',
]
components.map(item => Vue.component(item, require(`./libs/${item}.vue`).default))

// Vue.component('VueCountup', VueCountup)


