<template>
    <div>
        <template v-if="!multiple">
            <RadioGroup v-model="current" v-if="radio" :type="type">
                <Radio v-for="(val, key) in object" :label="key" :key="key" :disabled="disabled">{{ val }}</Radio>
            </RadioGroup>
            <Select v-model="current" :placeholder="placeholder" clearable filterable :disabled="disabled" v-else filter-by-label>
                <Option v-for="(val,key) in object" :key="key" :value="key" :label="val" />
            </Select>
        </template>
        <template v-else>
            <CheckboxGroup v-model="current" :placeholder="placeholder" multiple clearable filterable :disabled="disabled" v-if="checkbox">
                <Checkbox v-for="(val,key) in object" :key="key" :label="key">{{val}}</Checkbox>
            </CheckboxGroup>
            <Select v-model="current" :placeholder="placeholder" multiple clearable filterable :disabled="disabled" v-else filter-by-label>
                <Option v-for="(val,key) in object" :key="key" :value="key" :label="val" />
            </Select>
        </template>
    </div>
</template>
<script>
import { dict } from '@/config'
export default {
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: [Object, Array, String, Number],
        dict: { type: [Object, String], required: true },
        placeholder: String,
        multiple: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        labelInValue: Boolean,
        radio: { type: Boolean, default: false },
        checkbox: { type: Boolean, default: false },
        type: String
    },
    computed: {
        object() {
            return typeof this.dict == 'string' ? dict[this.dict] : this.dict
        },
        current: {
            get() {
                let val = this.value
                if (typeof value == 'number') {
                    val = this.value.toString()
                }
                if (this.multiple) {
                    // val = val ? val.split(',') : []
                    val = val ? val : []
                }
                return val
            },
            set(val) {
                let _val, obj = {}
                if (this.multiple) {
                    if (this.labelInValue) {
                        for (let key in val) {
                            obj[key] = this.dict[key]
                        }
                    }
                    // _val = val.join(',')
                    _val = val
                } else {
                    obj[val] = this.dict[val]
                    _val = val
                }
                this.$emit('change', _val)
                this.$emit('on-change', this.labelInValue ? obj : _val)
            }
        }
    }
}
</script>