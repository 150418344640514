<template>
    <div class="upload-photo">
        <!-- img格式为base64 -->
        <!-- <div class="upload" v-for="(item,index) in uploads" :key="index">
            <template v-if="item.status === 'finished'">
                <div class="upload-image-item">
                    <img :src="renderImg(item.url)" />
                </div>
                <Button @click="handleRemove(item,index)" type="error" size="small" v-show="!disabled">删除</Button>
            </template>
        </div> -->
        <van-uploader
            v-model="uploads"
            :accpet="accept"
            :capture="capture"
            :multiple="multiple"
            :disabled="disabled"
            :max-count="maxCount"
            :before-read="beforeRead"
            :after-read="uploadData"
        />
    </div>
</template>
<script>
import { _api, util } from '@/libs'
export default {
    name: 'uploadPhoto',
    model: { prop: 'img', event: 'change' },
    props: {
        img: { type: [String, Array] },
        fileList: { type: Array, default: () => [] },
        // 禁用组件
        disabled: Boolean,
        // 单图多图，默认单图
        single: { type: Boolean, default: true },
        // 上传文件类型 'image/*', 'video/*', 'audio/*'
        accept: { type: String, default: 'image/jpg,image/png,image/jpeg,image/bmp' },
        // 取值：相机、摄像、录音 ['camera', 'camcorder', 'microphone']
        capture: { type: String, default: '' },
        // 多张图片
        multiple: { type: Boolean, default: false },
        // 上传图片最大数
        maxCount: { type: Number, default: 1 },
    },
    data() {
        return {
        }
    },
    computed: {
        uploads: {
            get() {
                let arr = []
                if (this.single) {
                    if (this.img) {
                        arr.push({ name: this.img, url: this.img, status: 'finished' })
                    }
                } else {
                    if (this.img) {
                        let imgs = []
                        try {
                            imgs = JSON.parse(this.img)
                        }
                        catch {
                            imgs = this.img.split(',')
                        }
                        arr = imgs.map(item => {
                            return { name: item, url: item, status: 'finished' }
                        })
                    }
                }
                return arr
            },
            set(val) {
                if (this.single) {
                    const last = val[val.length - 1]
                    this.$emit('change', last && last.content)
                } else {
                    this.$emit('change', this.renderImgs(val))
                }
            }
        },
    },
    mounted() {
    },
    methods: {

        // 上传完成的图片
        renderImgs(uploads) {
            return JSON.stringify(uploads.filter(item => item.status == 'finished').map(item => item.content))
        },
        renderImg(img) {
            return img
            // TODO: 返回图片路径是否需要拼接
            // return _api.apiurl + '/' + img
        },
        
        // 删除图片
        handleRemove(i) {
            if (this.single) {
                this.uploads = []
                this.$emit('change', null)
            } else {
                this.uploads.splice(i, 1)
                this.$emit('change', this.renderImgs(this.uploads))
            }
        },
        // 上传前置处理 返回Boolean
        beforeRead(file) {
            return true;
            // return new Promise((res, rej) => {

            // })
        },
        // 上传图片至服务器
        uploadData(file) {
            // TODO: 上传图片接口，返回格式URL，base64编码upload组件不识别图片
            console.log("uploadData file:", file)
        }
    }
}
</script>

<style lang="less">
.upload-photo {
    .upload {
        display: inline-block;
        width: auto;
        margin: 5px;
        .upload-image-item {
            width: 120px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

}
</style>