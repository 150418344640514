import { request } from '@/libs'

// 上传文件 返回文件的id
export function fileUpLoadID(data) {
   return request({
        url: '/minio-netdisk/file/n',
        method: 'post',
        ContentType: 'multipart/form-data',
        data
   })
}

// 下载文件 返回xml文件
export function downloadFile(data) {
    return request({
        url: `/minio-netdisk/file/e/${data.fileId}`,
        method: 'get'
      })
}

// 获取用户列表
export function getUserByOrgId(params) {
  return request.post(`/huiyue-cloud-authserver/organization/selectAllUser`, params)
}

// 获取所有用户
export function getUserAll(params) {
  return request.get('/huiyue-cloud-authserver/v2/simple/userorg/all')
}