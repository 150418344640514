<template>
  <div class="navbar">
      <div class="navbar_content">
        <div class="navbar_left f-16 c-blue-e" @click="clickLeft" v-if="leftText" ><van-icon name="arrow-left" />{{ leftText }}</div>
        <div class="navbar_title ellipsis f-18 text-c">{{ title }}</div>
        <div class="navbar_right f-16 c-blue-e" @click="clickRight" v-if="rightText">{{ rightText }}</div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        title: { type: String, default: '' },
        leftText: { type: String, default: '返回' },
        rightText: { type: String, default: '' },
    },
    methods: {
        clickLeft() {
            this.$emit('click-left')
        },
        clickRight() {
            this.$emit('click-right')
        },
    }
}
</script>

<style lang="less" scoped>
.navbar {
    width: 100%;
    height: 50px;
    color: #333;
    box-shadow: 1px 1px 1px rgba(102, 102, 102, 0.5);
    .navbar_content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 50px;
        .navbar_left {
            position: absolute;
            left: 10px;
            top: 1px;
            &:hover {
                color: #0c81f5;
            }
        }
        .navbar_title {
            max-width: 60%;
        }
        .navbar_right {
            position: absolute;
            right: 10px;
            top: 1px;
            &:hover {
                color: #0c81f5;
            }
        }
    }
}
</style>