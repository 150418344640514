<template>
    <Select v-model="current" :placeholder="placeholder" clearable filterable :disabled="disabled" :size="size" :multiple="multiple" @on-change="change" :loading="padding" filter-by-label>
        <Option v-for="(item,i) in lines" :key="i" :value="item.researchLineId" :label="`${item.lineName||''}`" />
    </Select>
</template>

<script>
import { getAllLineEntity } from '@/libs/api/trace'
export default {
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        type: String,
        value: { type: [String, Number, Array], default: '' },
        placeholder: String,
        disabled: Boolean,
        size: String,
        labelInValue: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        isArray: Boolean,
        departmentFilter: { type: Boolean, default: false },
        search: { type: Boolean, default: false },
        enterpriseId: String,
        disposalType: String,
        isAll: Boolean
    },
    data() {
        return {
            current: null,
            padding: false,
            lineAll: []
        }
    },
    computed: {
        lines() {
            const data = this.lineAll
            return this.state ? data.filter(item => this.state.includes(item.state)) : data
        },
    },
    watch: {
        value() {
            if (!this.padding) {
                this.setCurrent()
            }
        }
    },
    methods: {
        change(val) {
            if(!this.padding) {
                const { isArray, multiple, labelInValue } = this
                this.$emit('change', multiple ? isArray ? val : val.join(',') : val)
                this.$emit('on-change', labelInValue ? multiple ? this.lineAll.filter(item => val.includes(item.researchLineId)) : this.lineAll.find(item => item.researchLineId == val) : val)
            }
        },
        setCurrent() {
            const { value, multiple } = this
            this.current = multiple ? typeof value == 'string' ? value.split(',') : value : value
        },
        getData() {
            this.padding = true
            getAllLineEntity({ name: '' }).then(res => {
                this.padding = false
                this.lineAll = res.data
                this.$nextTick(() => this.setCurrent())
            })
        }
    },
    created() {
        if (!this.lines.length) {
            this.getData()
        }
    }
}
</script>