<template>
    <div>
        <component :is="item.type == 1 ? 'Submenu':  item.isHidden ? 'MenuItem' : ''" :name="item.path" v-for="(item,index) in menus" :key="index" @click.native="click(item)">
            <span :slot="item.type == 1 ? 'title' : null">
                <Icon :custom="item.icon" /> {{item.title}}
            </span>
            <VMenuItem :menus="item.children" :click="click" />
        </component>
    </div>
</template>
<script>
export default {
    props: {
        menus: { type: Array },
        click: Function,
    }
}
</script>