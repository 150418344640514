<template>
    <Select v-model="current" :placeholder="placeholder" clearable filterable :disabled="disabled" :size="size" :multiple="multiple" @on-change="change" :loading="padding" filter-by-label>
        <Option v-for="(item,i) in points" :key="i" :value="item.researchPointId" :label="`${item.pointName||''}`" />
    </Select>
</template>

<script>
import { getAllEntity } from '@/libs/api/point'
export default {
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        type: String,
        value: { type: [String, Number, Array], default: '' },
        placeholder: String,
        disabled: Boolean,
        size: String,
        labelInValue: { type: Boolean, default: false },
        multiple: { type: Boolean, default: true },
        isArray: Boolean,
        departmentFilter: { type: Boolean, default: false },
        search: { type: Boolean, default: false },
        enterpriseId: String,
        disposalType: String,
        isAll: Boolean
    },
    data() {
        return {
            current: null,
            padding: false,
            pointAll: []
        }
    },
    computed: {
        points() {
            const data = this.pointAll
            return this.state ? data.filter(item => this.state.includes(item.state)) : data
        },
    },
    watch: {
        value() {
            if (!this.padding) {
                this.setCurrent()
            }
        }
    },
    methods: {
        change(val) {
            if(!this.padding) {
                const { isArray, multiple, labelInValue } = this
                this.$emit('change', multiple ? isArray ? val : val.join(',') : val)
                this.$emit('on-change', labelInValue ? multiple ? this.pointAll.filter(item => val.includes(item.researchPointId)) : this.pointAll.find(item => item.researchPointId == val) : val)
            }
        },
        setCurrent() {
            const { value, multiple } = this
            this.current = multiple ? typeof value == 'string' ? value.split(',') : value : value
        },
        getData() {
            this.padding = true
            getAllEntity({ name: '' }).then(res => {
                this.padding = false
                this.pointAll = res.data
                this.$nextTick(() => this.setCurrent())
            })
        }
    },
    created() {
        if (!this.points.length) {
            this.getData()
        }
    }
}
</script>