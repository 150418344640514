import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/modules'
import iView from 'view-design'

Vue.use(VueRouter)
// Vue.use(iView)

// iView.Input.props.placeholder.default = '请输入'
// iView.InputNumber.props.placeholder.default = '请输入'
// iView.InputNumber.props.value.default = null

if (location.search.length > 0) {
  let url = location.search
  url.split('?')[1].split('&').forEach(item => {
    localStorage.setItem(item.split('=')[0], item.split('=')[1])
  })
}


const router = new VueRouter({ mode: 'history', base: process.env.BASE_URL, routes })

// 解决当前路由重复点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach(async(to, from, next) => {
  iView.LoadingBar.start() // 开始进度条

  const session = localStorage.getItem('token_authserver')
  const user = store.state.user
  const login = { path: 'login' }
  // url去除token
  if(location.search.includes("token_authserver")) {
    location.href = decodeURIComponent(location.origin)
  }
  


  if(to.path === '/login') {
    next()
  } else {
    if(to.path.includes('activityShare') || to.path.includes('register') || to.path.includes('notFound')) {
      next()
    } else {
      if(session) {
        let token = await store.dispatch('user/getIsLogin')
        if(token == null || token == '') {
          next(login)
        } else {
          if(!user.userId) {
            let userId = await store.dispatch('user/getUserInfoId')
            // 获取用户角色code
            // const payload = await store.dispatch('user/getRoleCode')
            // console.log('payload:', payload)
          }
          if(!user.roleCode) {
            let roleCode = await store.dispatch('user/getRoleCode')
          }
          if(user.isAuth) {
            if(!user.routers) {
              store.commit('user/SET_ROUTER', {})
              router.addRoutes(user.routers)
              const [dataV, system, config] = user.routers
              const path = dataV.index || config.index || system.index
              if(path) {
                if(to.path == '/') {
                  next(path)
                } else {
                  next(to)
                }
              } else {
                next()
              }
            } else {
              next()
            } 

          } else {
            next('/notFound')
          }
            
        }
      } else {
        next(login)
      }
    }
  }


  
})

router.afterEach((to, from) => {
  iView.LoadingBar.finish()
  if (to.matched.length > 0) {
    store.commit('ivew/SET_BREADCRUMB_DATA', to)
  } else {
    iView.Notice.warning({ desc: '暂无此页面，请刷新或稍后重试。' })
    // router.push(from)
  }
  store.commit('ivew/SET_RUNTIME_CLEAR')
})


export default router
