let isHidden = true
const configMenus = [
    {
        title: '研学管理',
        path: 'dataManage',
        icon: '',
        type: 1,
        isHidden: isHidden,
        children: [
            {
                title: '研学点',
                path: 'point',
                fullPath: '/config/dataManage/point',
                icon: '',
                type: 2,
                isHidden: isHidden,
            },
            {
                title: '研学路线',
                path: 'trace',
                fullPath: '/config/dataManage/trace',
                icon: '',
                type: 2,
                isHidden: isHidden,
                children: [
                    {
                        title: '研学路线编辑',
                        path: 'tracePage',
                        fullPath: '/config/dataManage/trace/tracePage',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    }
                ]
            },
            // {
            //     title: '研学基地',
            //     path: 'researchSchools',
            //     fullPath: '/config/dataManage/researchSchools',
            //     icon: '',
            //     type: 2,
            //     isHidden: isHidden,
            // },
            // {
            //     title: '研学课程',
            //     path: 'courses',
            //     fullPath: '/config/dataManage/courses',
            //     icon: '',
            //     type: 2,
            //     isHidden: isHidden,
            // },
            {
                title: '研学活动',
                path: 'activity',
                fullPath: '/config/dataManage/activity',
                icon: '',
                type: 2,
                isHidden: isHidden,
                children: [
                    {
                        title: '研学活动编辑',
                        path: 'activityPage',
                        fullPath: '/config/dataManage/activity/activityPage',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                    {
                        title: '研学活动查看',
                        path: 'activityView',
                        fullPath: '/config/dataManage/activity/activityView',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                    {
                        title: '研学活动汇总',
                        path: 'activityTotal',
                        fullPath: '/config/dataManage/activity/activityTotal',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                    {
                        title: '研学活动资源',
                        path: 'activityResource',
                        fullPath: '/config/dataManage/activity/activityResource',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                    {
                        title: '研学活动资源',
                        path: 'activityResourcePage',
                        fullPath: '/config/dataManage/activity/activityResourcePage',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                ]
            },
            {
                title: '研学资讯',
                path: 'researchNews',
                fullPath: '/config/dataManage/researchNews',
                icon: '',
                type: 2,
                isHidden: isHidden,
                children: [
                    {
                        title: '研学资讯编辑',
                        path: 'researchNewsPage',
                        fullPath: '/config/dataManage/researchNews/researchNewsPage',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                    {
                        title: '研学资讯查看',
                        path: 'researchNewsView',
                        fullPath: '/config/dataManage/researchNews/researchNewsView',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                ]
            },
            {
                title: '研学全景',
                path: 'panorama',
                fullPath: '/config/dataManage/panorama',
                icon: '',
                type: 2,
                isHidden: isHidden,
                children: [
                    {
                        title: '研学全景编辑',
                        path: 'panoramaPage',
                        fullPath: '/config/dataManage/panorama/panoramaPage',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                    {
                        title: '研学全景查看',
                        path: 'panoramaView',
                        fullPath: '/config/dataManage/panorama/panoramaView',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                ]
            },
            {
                title: '研学漫游',
                path: 'roam',
                fullPath: '/config/dataManage/roam',
                icon: '',
                type: 2,
                isHidden: isHidden,
                children: [
                    {
                        title: '研学漫游编辑',
                        path: 'roamPage',
                        fullPath: '/config/dataManage/roam/roamPage',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                    {
                        title: '研学漫游预览',
                        path: 'roamView',
                        fullPath: '/config/dataManage/roam/roamView',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                ]
            },
            {
                title: '研学活动分享',
                path: 'shareActivityList',
                fullPath: '/config/dataManage/shareActivityList',
                icon: '',
                type: 2,
                isHidden: isHidden,
            }
        ]
    },
    {
        title: '智能控制',
        path: 'intelligentControl',
        icon: '',
        type: 1,
        isHidden: isHidden,
        children: [
            {
                title: '智能教室管理',
                path: 'smartClassroom',
                fullPath: '/config/intelligentControl/smartClassroom',
                icon: '',
                type: 2,
                isHidden: isHidden,
                children: [
                    {
                        title: '智能教室场景管理',
                        path: 'smartScene',
                        fullPath: '/config/intelligentControl/smartClassroom/smartScene',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                    {
                        title: '智能教室场景设置',
                        path: 'smartScenePage',
                        fullPath: '/config/intelligentControl/smartClassroom/smartScenePage',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                    {
                        title: '智能设备设置',
                        path: 'smartDevices',
                        fullPath: '/config/intelligentControl/smartClassroom/smartDevices',
                        icon: '',
                        type: 2,
                        isHidden: !isHidden, 
                    },
                ]
            },
            {
                title: '智能教室设置',
                path: 'internetOfThing',
                fullPath: '/config/intelligentControl/internetOfThing',
                icon: '',
                type: 2,
                isHidden: isHidden,
            }
        ]
    },
    {
        title: '系统设置',
        path: 'setting',
        icon: '',
        type: 1,
        isHidden: isHidden,
        children:[
            {
                title: '账户设置',
                path: 'account',
                fullPath: '/config/setting/account',
                icon: '',
                type: 2,
                isHidden: isHidden,
            },
            {
                title: '智能控制',
                path: 'intelligentControl',
                fullPath: '/config/setting/intelligentControl',
                icon: '',
                type: 2,
                isHidden: isHidden,
            },
        ]
    }
]
export default configMenus