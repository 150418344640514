import mqtt from 'mqtt';
import {
  util
} from '@/libs'
export default class MqttServer {
  constructor(connectUrl, topic, params) {
    const options = Object.assign({
      // 认证信息
      clientId: util.uuid(), //随机
      username: "demo_0", //⽤户id
      password: "zzzz1234", //密码
      clean: true, // true: 清除会话, false: 保留会话
      connectTimeout: 4000, // 超时时间
    }, params)

    // 创建
    this.client = mqtt.connect(
      `ws://${
        "192.168.1.1"
        // process.env.NODE_ENV == "production" ? "xingyaedu.cn" : "192.168.1.1"
      }:8083/mqtt`,
      options
    );
    // 连接
    this.client.on('connect', () => {
      console.log("MQTT连接成功！");
    })
    // 重连
    this.client.on('reconnect', () => {
      console.log("正在重连.....");
    });
    // 关闭
    this.client.on("close", function (error) {
      if (error) {
        // console.log(error)
      } else {
        console.log("客户端已断开.....");
      }

    });
    this.client.on("offline", function () {

    });
    //当客户端无法连接或出现错误时触发回调
    this.client.on("error", (error) => {
      console.log("客户端出现错误....." + error);
    });
    this.client.on("packetsend", (packet) => {
      if (packet && packet.payload) {
        console.log("客户端已发出数据包....." + packet.payload);
      }
    });

    console.log("mqttServer", this, this.client)

  }

  // 断开连接
  disconnect() {
    try {
      if (this.client && this.client.connected) {
        this.client.end()
      }
    } catch (err) {
      console.log(err)
    }
  }

  // 重新连接
  reconnect() {
    this.client.reconnect()
  }

  // 关闭
  close() {
    try {
      if (this.client && this.client.connected) {
        this.client.end()
        this.client = null
      }
    } catch (err) {
      console.log(err)
    }
  }

  // 发布消息
  publish(topic, params) {
    const message = JSON.stringify(params)
    if (this.client && this.client.connected) {
      this.client.publish(topic, message, {
        qos: 1
      }, (err) => {
        if (err)
          console.log(err)
        else
          console.log("发布消息成功")
      })
    }
  }

  // 订阅主题
  subscribe(topic) {
    if (this.client && this.client.connected) {
      this.client.subscribe(topic, { qos: 0 }, (err) => {
        if(err) {
          console.log(err)
        } else {
          console.log(`订阅成功`)
        }
      })
    }
  }

  
  // 取消订阅
  unsubscribe(topic) {
    if (this.client && this.client.connected) {
      this.client.unsubscribe(topic, (error) => {
        if (error) {
          console.log(error)
        } else {
          console.log('取消订阅成功')
        }
      })
    }
  }



}