import axios from 'axios'
import _api from './_api'
import store from '@/modules'
import { Toast } from 'vant'
import router from '@/router'

const _http = axios.create({ baseURL: _api.apiurl, timeout: 60000 })

_http.interceptors.request.use(config => {
    const url = config.url.match(/\{(.*?)\}/g)
    const param = config.params || config.data
    if (url && url.length) {
        url.map(item => {
            const key = item.slice(1, -1)
            const value = param && param[key]
            if (value) {
                config.url = config.url.replace(item, value)
            } else {
                config.url = config.url.replace(item, '')
            }
        })
    }
    // 设置token 
    const session = store.state.user.session
    if (session) {
        config.headers['s-token'] = session
    }
    // iview.LoadingBar.start()
    return config
}, error => error)

_http.interceptors.response.use(response => {
    const session = store.state.user.session
    const resession = response.headers['s-token']
    // iview.LoadingBar.finish()
    if (resession && (!session || session != resession)) {
        store.commit('user/USER_INFO_SESSION', resession)
    }
    if (response.data.code != 200) {
        const { params, data } = response.config
        if (!((params && params.__hideNotice) || (data && data.__hideNotice))) {
            Toast.fail({ content: response.data.desc })
        }
        const err = new Error(response.data.desc)
        throw err
    } else {
        if (response.config.method == 'post' && !response.config.__hideNotice) {
            Toast.success({ content: '提交成功' })
        }
        return response.data
    }
}, error => {
    // iview.LoadingBar.finish()
    if (error.response && error.response.data && error.response.data.status == 480) {
        store.commit('user/USER_INFO_LOGINOUT')
        router.push('/login')
    }
    return Promise.reject(error)
})

export default _http