<template>
    <Row type="flex" class="p-5 l-h-24">
        <Col class="text-r" :style="labelStyles">
        <span v-if="label">{{label}}</span>
        <slot name="label" v-else-if="$slots.label"></slot>
        </Col>
        <Col :style="valueStyles">
        <span v-if="value">{{value}}</span>
        <slot slot-scope v-else-if="$slots.default"></slot>
        </Col>
    </Row>
</template>
<script>
export default {
    name: "Cell",
    data() {
        return {}
    },
    computed: {
        labelStyles() {
            return {
                width: `${this.labelWidth}px`
            }
        },
        valueStyles() {
            return {
                width: `calc(100% - ${this.labelWidth + 16}px)`
            }
        }
    },
    props: {
        label: [String, Number],
        value: [String, Number, Array]
    },
    created() {
        this.labelWidth = parseInt(this.$parent.labelWidth) || 180
    }
};
</script>

