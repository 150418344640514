export default class MapBase {
    constructor(map) {
        this.map = map
        return this
    }
    lngLat(lng, lat) {
        return new T.LngLat(lng, lat)
    }
    icon(icon, val = 18) {
        const iconSize = new T.Point(val, val)
        const iconAnchor = new T.Point(val / 2, val / 2)
        return new T.Icon({
            iconUrl: `/static/images/model/${icon}.png`,
            iconSize,
            iconAnchor
        })
    }
    marker(position, icon) {
        const marker = icon ? new T.Marker(position, { icon }) : new T.Marker(position)
        this.map.addOverLay(marker)
        return marker
    }
    label(content = '', position, at = true) {
        const label = new T.Label({
            text: content,
            offset: new T.Point(10, -10),
            position
        })
        at && this.map.addOverLay(label)
        return label
    }
    polyline(coords, lineStyle) {
        const polyline = new T.Polyline(coords, lineStyle)
        this.map.addOverLay(polyline)
        return polyline
    }
    polygon(coords, option) {
        const polygon = new T.Polygon(coords, option)
        this.map.addOverLay(polygon)
        return polygon
    }
}