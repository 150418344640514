import Vue from 'vue'
import Vuex from 'vuex'
import { _http } from '@/libs'
Vue.use(Vuex)
const store = new Vuex.Store({})


store.$data = data => {
    return Object.assign({
        data: {},
        datas: [],
        outputPage: { totalResult: 0 }
    }, data)
}


store.$set = (model, key = 'id') => {
    return (state, payload) => {
        const { datas, outputPage } = state[model]
        if (payload[key]) {
            datas.splice(datas.findIndex(item => item[key] == payload[key]), 1)
            outputPage.totalResult--
        } else {
            for (let key in payload) {
                const item = payload[key]
                if (item) {
                    state[model][key] = item
                }
            }
            if (payload.data && payload.data[key]) {
                const node = datas.find(item => item[key] == payload.data[key])
                if (node) {
                    Object.assign(node, payload.data)
                } else {
                    datas.unshift(payload.data)
                    outputPage.totalResult++
                }
            }
        }
    }
}

store.$get = (api, type, model, _extend = '') => {
    return async (store, params = {}) => {
        const payload = await _http[type](_extend ? `${api}/${_extend}` : api, type == 'post' ? params : { params })
        if (model) {
            store.commit(`set_${model}`, type == 'delete' ? params : payload)
        }
        return payload
    }
}

export default store