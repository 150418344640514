<template>
  <div
    class="info-window"
    :style="modal && modal.style"
    v-show="modal && modal.show"
  >
    <div class="title ellipsis">
      {{ modal.data.name }}
      <div class="f-r c-h close" @click="($event) => (modal.show = false)">
        X
      </div>
    </div>
    <div class="content">
      <div class="show">
        <img class="show_img" :src="modal.data.showImage" alt="" />
      </div>
      <div class="show_con">
        <div>经度：{{ modal.data.lng }}</div>
        <div>纬度：{{ modal.data.lat }}</div>
        <div class="desc c-h" @click="goUrl">查看详情</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modal: { type: Object, require: true },
  },
  data() {
    return {};
  },
  methods: {
    goUrl() {
      console.log(this.modal.data.type);
      if (this.modal.data.type === "point") {
        this.$router.push({
          path: "/dataV/index/point",
          query: { id: this.modal.data.id },
        });
      } else {
        this.$router.push({
          path: "/dataV/index/line",
          query: { id: this.modal.data.id },
        });
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.info-window {
  position: absolute;
  z-index: 10;
  width: 260px;
  height: 130px;
  color: #fff;
  background: #fff;
  border-radius: 4px;
  .title {
    color: #413838;
    font-size: 15px;
    padding: 8px 10px;
    border-bottom: 1px solid #d4d4d4;
    .close:hover {
      color: #5c7aff;
    }
  }
  .content {
    margin: 10px 0;
    color: #939393;
    font-size: 14px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    .show {
      width: 70px;
      .show_img {
        width: 56px;
        height: 56px;
      }
    }
    .show_con {
      width: calc(100% - 70px);
    }
  }
  .desc {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #5cadff;
    &:hover {
      color: #5c7aff;
    }
  }
}
.info-window:after {
  content: "";
  position: absolute;
  border-width: 10px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1) transparent transparent transparent;
  top: 130px;
  left: 120px;
}
</style>
