import store from './index'
import { _http, _api } from '@/libs'
import { config } from '@/config'
const { base_title, title, copyright } = config.page
//项目整体框架存储
store.registerModule('ivew', {
  namespaced: true,
  state: {

    // 页面主题缓存
    frame: JSON.parse(localStorage.getItem('frame')) || { layout: false, theme: 'light' },

    // 导航条
    breadcrumb: [],

    //菜单
    menu: {
      open: [],
      active: null,
    },

    // 全局页面配置
    config: { title: base_title || title, copyright },

    // 实时
    runtime: [],

    theme: localStorage.getItem('theme') || 'blue',

    weather: '',
    isLoading: false,
  },
  mutations: {

    // 设置菜单样式和宽度
    SET_FRAME_STORAGE_DATA(state, frame) {
      state.frame = frame
      localStorage.setItem('frame', JSON.stringify(frame))
    },

    // 添加实时
    SET_RUNTIME_DATA(state, data) {
      state.runtime.push(data)
    },

    // 删除实时
    SET_RUNTIME_CLEAR(state, data) {
      state.runtime.map(item => clearInterval(item))
      state.runtime = []
    },

    // 设置面包屑导航和展开激活菜单
    SET_BREADCRUMB_DATA(state, to) {
      state.breadcrumb = to.matched
      const [i1, i2, i3, i4, i5] = to.path.split('/')
      const { menu, isLogin } = store.state.user

      if (isLogin) {
        state.menu = {
          model: i3,
          active: i4 || i3,
          open: [i5, i4, i3],
          list: menu.config
        }
      }
      document.title = [...to.matched.map(item => item.meta.title), state.config.title].join('-')
    },

    set_theme(state, theme) {
      state.theme = theme
      localStorage.setItem('theme', theme)
    },

    set_weather(state, weather) {
      state.weather = weather
    },
    set_isLoading(state, isLoading) {
      state.isLoading = isLoading
    },
  }
})

export default store