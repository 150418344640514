
import store from './index'
import { _http, _api, request, dist } from '@/libs'
import { config } from '@/config'

store.registerModule('user', {
    namespaced: true,

    // 存储处理过的菜单和路由
    state: {
        isLogin: false,
        organization: {},
        userRole: false,
        userId: null,
        organizationInfo: [],
        userInfo: [],
        userInfoFrom: {},
        readonlyFrom: {},
        menu: [],
        role: null,
        roleCode: [],
        isAuth: null,
    },


    mutations: {
        USER_LOGIN(state, payload) {
            state.isLogin = payload.isLogin
            store.commit('user/SET_USERID', payload.user_id)
            store.commit('user/SET_ORGANIZATION', payload.organization)
            store.commit('user/SET_USERROLE', payload.role)
            store.commit('user/SET_ROUTER', payload)
            localStorage.setItem(payload.tokenName, payload.tokenValue)
        },
        SET_ROUTER(state, payload) {
            state.menu = config.createMenus()
            state.isLogin = true
            if (!state.routers) {
                state.routers = config.createRouters(state.menu)
            }
        },

        SET_USERID(state, userId) {
            state.userId = userId
        },

        SET_ORGANIZATION: (state, data) => {
            state.organization = data && data[0]
        },

        SET_USERROLE: (state, data=[]) => {
            if(data.length) {
                let roleCode = []
                data.forEach(item => {
                    roleCode.push(item.roleCode)
                    store.commit('user/USER_ROLE_CODE', roleCode)
                    if (item.roleCode === 'admin') {
                        state.userRole = true
                    }
                })
            }
        },
        
        SET_ORGANIZATIONINFO: (state, data) => {
        state.organizationInfo = data
        },

        USER_INFO_LOGINOUT(state) {
            Object.assign(state, { user: {}, login: false, session: '', menu: [], role: {} })
            state.routers = null
            // 删除token
            for(let i in localStorage) {
                if(i.split('_')[0] === 'token') {
                    localStorage.removeItem(i)
                }
            }
        },

        USER_ROLE_CODE(state, data) {
            state.roleCode = data
            store.commit('user/SET_IS_AUTH', data)
        },

        SET_IS_AUTH(state, roleCode) {
            state.isAuth = roleCode.includes('admin') || roleCode.includes('mechanismAdmin') || roleCode.includes('teach') || roleCode.includes('yxpt-t')
        }


    },


    actions: {
        // 登录
        async userLogin(store, params) {
            const payload = await request.post(`${_api.base.login}?name=${params.name}&pwd=${params.pwd}`)
            if(payload.code == 200) {
                store.commit('USER_LOGIN', payload.data)
            }
        },

        async getIsLogin() {
            const payload = await request.post(_api.base.isLogin)
            return payload.data
        },

        // 获取用户ID
        async getUserInfoId(store, params) {
            const payload = await request.post(_api.base.getUserInfoId)
            const { code, organization, role, user_id } = payload
            store.commit('SET_ORGANIZATION', organization)
            store.commit('SET_USERROLE', role)
            store.commit('SET_USERID', user_id)
            return user_id
        },

        // 获取用户信息
        async getUserInfo(store, params) {
            const payload = await request.post(_api.base.getUserInfoById, params)
            store.commit('USER_INFO_DATA', payload.data)
        },

        // 获取用户所在的组织信息
        async getOrganizationInfo(store, params) {
            const payload = await request.get(_api.base.getOrganizationInfo + params.organizationId)
            console.log("getOrganizationInfo payload:", payload)
            return payload
        }, 

        // 获取授权码
        async getAuthCode(store, params) {
            const payload = await request.get(`${_api.base.authorize}?response_type=code&client_id=${params.clientId}&redirect_uri=${params.redirectUri}&scope=userinfo`)
            return payload
        },

        // 根据授权码获取网盘token
        async getTokenNetdisk(store, params) {
            const { code, data } = await request.get('/minio-netdisk/oauth/codeLogin?code=' + params.authCode)
            if(code == 200) {
                localStorage.setItem(data.tokenName, data.tokenValue)
            }
        },

        // 根据授权码获取应用token
        async getTokenMarket(store, params) {
            const { code, data } = await request.get('/huiyue-cloud-market/oauth/codeLogin?code=' + params.authCode)
            if(code == 200) {
                localStorage.setItem(data.tokenName, data.tokenValue)
            }
        },

        // 根据授权码获取研学token
        async getTokenResearch(store, params) {
            const { code, data } = await request.get('/yanxue-app/oauth/codeLogin?code=' + params.authCode)
            if(code == 200) {
                localStorage.setItem(data.tokenName, data.tokenValue)
            }
        },

        // 获取wxState
        async getWxState(store, params) {
            const payload = await request.get('/huiyue-cloud-authserver/wx/state', { params })
            return payload
        },

        // 获取微信绑定状态
        async getWxInfo(store, params) {
            const payload = await request.get('/huiyue-cloud-authserver/wx/info', { params })
            return payload
        },

        // 绑定微信登陆
        async bindWeChat(store, params) {
            const payload = await request.get('/huiyue-cloud-authserver/wx/bind', { params })
            return payload
        },
        // 微信登陆解绑
        async delBindWeChat(store, params) {
            const payload = await request.delete('/huiyue-cloud-authserver/wx/del', { params })
            return payload
        },

        // wx登录
        async wxLogin(store, params) {
            const payload = await request.get('/huiyue-cloud-authserver/wx/login', { params })
            return payload
        },

        // 退出登录
        async getLogOut(store, params) {
            const payload = await request.post(_api.base.logOut)
            store.commit('USER_INFO_LOGINOUT', payload.data)
        },

        // 获取当前登录的用户拥有的RoleCode
        async getRoleCode(store, params) {
            const payload = await request.get('/huiyue-cloud-authserver/role/hasroles', { params })
            store.commit('USER_ROLE_CODE', payload.data)
            return payload
        },

        // 修改密码
        async changePassword(store, params) {
            const payload = await request.put('/huiyue-cloud-authserver/user/changePassword', params)
            return payload
        }
    }
})

export default store