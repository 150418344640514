import _http from './_http'
import _api from './_api'
import store from '@/modules'
const dist = {
    get(id, name) {
        const item = store.state.setup.areas.datas.find(node => node.id == id)
        return name ? item && item.areaName : item
    },
    full(id) {
        if (id) {
            const d1 = `${id.substr(0, 2)}0000`
            const d2 = `${id.substr(0, 4)}00`
            let text1 = dist.get(d1, true), text2, text3
            if (id == d1) {
                return text1
            } else {
                text2 = dist.get(d2, true)
                if (id == d2) {
                    return `${text1}-${text2}`
                } else {
                    return `${text1}-${text2}-${dist.get(id, true)}`
                }
            }
        }
    },
    row(h, w) {
        return h('span', dist.full(w.row.areaId || w.row.area_id))
    },
    direction(angle) {
        if (angle == 0 || angle == 360)
            return '北'
        else if (angle > 0 && angle < 90)
            return '东北'
        else if (angle == 90)
            return '东'
        else if (angle > 90 && angle < 180)
            return '东南'
        else if (angle == 180)
            return '南'
        else if (angle > 180 && angle < 270)
            return '西南'
        else if (angle == 270)
            return '西'
        else if (angle > 270 && angle < 360)
            return '西北'
    },
   
}

export default dist
