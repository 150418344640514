import { request } from '@/libs'

/** 
 * 研学点API
 */

// 根据用户访问权限查询已有研学点
export function getPointAll() {
    return request.get('/yanxue-app/research/point/all')
}

// 模糊查询所有已公开的研学点
export function getAllEntity(params) {
    return request.post(`/yanxue-app/research/point/getallentity/namelike?name=${params.name}`, params)
}

// 分页查询 根据研学点名称分页模糊查询
export function getPoint(params) {
    // return request.post(`/yanxue-app/research/point/findbypage?id=${params.userId}`, params)
    // return request.post(`/yanxue-app/research/point/findbypage/namelike?ownerId=${params.userId}&pointName=${params.pointName}`, params)
    return request({
        url: '/yanxue-app/research/point/findbypage/namelike',
        method: 'post',
        data: params,
        params: {
            ownerId: params.ownerId,
            pointName: params.pointName
        }, 
    })
}

// 根据研学点id获取详情
export function getPointById(params) {
    return request.get('/yanxue-app/research/point/one', { params })
}

// 添加
export function addPoint(params) {
    return request.post('/yanxue-app/research/point/add', params)
}

// 编辑
export function updatePoint(params) {
    return request.put(`/yanxue-app/research/poiont/update?id=${params.researchPointId}`, params)
}

// 删除
export function deletePoint(params) {
    return request.delete('/yanxue-app/research/point/delete', { params })
}
