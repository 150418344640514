
import { config } from '@/config'

Date.format = (date, fmt) => {
    date = Date.new(date)
    fmt = fmt || 'yyyy-MM-dd HH:mm:ss'
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'H+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
        }
    }
    return fmt;
}


// 参数分别为日期对象，增加的类型，增加的数量
Date.puls = (date, key, n) => {
    switch (key) {
        case 's':
            return new Date(Date.parse(date) + (1000 * n));
        case 'm':
            return new Date(Date.parse(date) + (60000 * n));
        case 'h':
            return new Date(Date.parse(date) + (3600000 * n));
        case 'd':
            return new Date(Date.parse(date) + (86400000 * n));
        case 'w':
            return new Date(Date.parse(date) + ((86400000 * 7) * n));
        case 'M':
            return new Date(date.getFullYear(), date.getMonth() + n, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
        case 'y':
            return new Date(date.getFullYear() + n, date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    }
}

Date.new = (val) => {
    if (val) {
        if (typeof val === 'object') {
            return val
        } else if (typeof val === 'string') {
            return new Date(val.replace(/-/g, '/'))
        } else {
            return new Date(val)
        }
    } else return new Date()
}

// 返回时间间隔 xx天xx小时xx分钟xx秒
Date._interval = (_t) => {
    return Math.floor(_t / 86400) + '天' + Math.floor(_t % 86400 / 3600) + '小时' + Math.floor(_t % 86400 % 3600 / 60) + '分钟'
}



const self = {}

const util = {



    // 全屏
    fullScreen(element = document) {
        var el = element instanceof HTMLElement ? element : document.documentElement;
        var rfs = el.requestFullscreen || el.webkitRequestFullscreen || el.mozRequestFullScreen || el.msRequestFullscreen;
        if (rfs) {
            rfs.call(el);
        } else if (window.ActiveXObject) {
            var ws = new ActiveXObject("WScript.Shell");
            ws && ws.SendKeys("{F11}");
        }
    },

    // fn 为一个方法，扩展其为一个数组元素
    extendArrayByNumber(start, end, fn, step = 1) {
        if (typeof fn == 'function') {
            let arr = []
            for (let i = start; i <= end; i = i + step) {
                arr.push(fn(i))
            }
            return arr
        } else {
            throw 'args error'
        }
    },



    // 返回uuid（有极小可能重复）
    uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    // 表单验证

    formValidate(ref, modal, promise) {
        ref.validate(valid => {
            if (valid) {
                promise().then(res => {
                    if (modal) {
                        Object.assign(modal, { show: false, loading: true })
                    }
                }, res => {
                    modal.loading = false
                    setTimeout(() => modal.loading = true)
                })
            } else {
                if (modal) {
                    modal.loading = false
                    setTimeout(() => modal.loading = true)
                }

            }
        })
    },

    // 异步加载天地图
    initTmap() {
        return self.initTmapPromise = self.initTmapPromise || new Promise((resolve, reject) => {
            var script = document.createElement("script");
            if(script.readyState) {
                script.onreadystatechange = () => {
                    if(script.readyState == 'complete' || script.readyState == 'loaded') {
                        resolve(window.T)
                    }
                }
            } else {
                script.onload = () => {
                    resolve(window.T)
                }
            }
            const tMapKey = ['32943f8a8cee5d0aba41ca81477690b2', '4b9163fdcca4dc7b381c53138ea73d47', '8d43e58373beab80419be770377fc195']
            const randomNumber = Math.floor(Math.random() * 3); // 获取一个0到2之间的整数
            Object.assign(script, { type: 'text/javascript', src: 'https://api.tianditu.gov.cn/api?v=4.0&tk=' + tMapKey[randomNumber], onerror: reject })
            document.head.appendChild(script);
        })
    },
    deepcopy(obj, cache = []) {

        function find(list, f) {
            return list.filter(f)[0]
        }

        // just return if obj is immutable value
        if (obj === null || typeof obj !== 'object') {
            return obj
        }

        // if obj is hit, it is in circular structure
        const hit = find(cache, c => c.original === obj)
        if (hit) {
            return hit.copy
        }

        const copy = Array.isArray(obj) ? [] : {}
        // put the copy into cache at first
        // because we want to refer it in recursive deepcopy
        cache.push({
            original: obj,
            copy
        })

        Object.keys(obj).forEach(key => {
            copy[key] = util.deepcopy(obj[key], cache)
        })

        return copy
    },


    //dataUrl转blob
    urlToBlob(url) {
        var arr = url.split(','), mime = arr[0].match(/:(.*?);/)[1]
        var bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], { type: mime })
    },

    // jsontoxml
    jsonToCameraXml(json) {
        const xmt = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>"
        let text = ''
        const JT = obj => {
            for (let key in obj) {
                if (typeof obj[key] == 'object') {
                    text = text + `<${key}>${JT(obj[key])}</${key}>`
                } else {
                    text = text + `<${key}>${obj[key]}</${key}>`
                }
            }
            return text
        }
        return xmt + JT(json)
    },


    handleSpinCustom(_this, text) {
        _this.$Spin.show({
            render: (h) => {
                return h('div', [
                    h('Icon', {
                        'class': 'spin-loading',
                        props: {
                            type: 'ios-loading',
                            size: 18
                        }
                    }),
                    h('div', text)
                ])
            }
        })
    },

    /**函数的去抖动**/
    debounce(method, delay = 500) {
        let timer = null;
        return function () {
            const _this = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                method.apply(_this, args);
            }, delay);
        }
    },
    /**函数的节流**/
    throttle(method, interval = 500) {
        let timer = null
        let lastTime = null
        return function () {
            const _this = this, args = arguments;
            const nowTime = Date.new()
            if (lastTime && nowTime - lastTime < interval) {
                clearTimeout(timer)
                timer = setTimeout(function () {
                    lastTime = nowTime
                    method.apply(_this, args)
                }, interval);
            } else {
                lastTime = nowTime
                method.apply(_this, args)
            }
        }
    },


    // 返回数字保留小数位
    tofixed(count, n = 2) {
        return count != null ? parseFloat(count).toString().indexOf('.') > -1 ? parseFloat(count).toFixed(n) : count : ''
    },

    // 生成树形结构，auth选中，fullpath全路径，fullid全id
    treeFn(arr, pid, { auth, fullpath = '', fullid = [], expand = true } = {}) {
        return arr.filter(item => item.parentId == pid).map(item => {
            const _fullpath = `${fullpath}/${item.path}`
            const _fullid = [...fullid, item.id]
            const { id, name, reportType, areaName } = item
            const title = name || reportType || areaName
            const children = util.treeFn(arr, id, { auth, fullpath: _fullpath, fullid: _fullid, expand })
            return {
                title,
                label: title,
                value: id,
                checked: typeof auth == 'function' ? auth(item, children) : auth && auth.some(item => item.id == id),
                node: item,
                expand: expand,
                fullpath: _fullpath,
                children
            }
        }).sort((a, b) => a.node.sort - b.node.sort)
    },

    treeFns(arr, _type, pid, { expand = false } = {}) {
        let data = []
        if(_type == 1) {
            data = arr.filter(item => item.type == _type)
        } else if (_type == 2) {
            data = arr.filter(item => item.type == _type && item.officeId == pid)
        } else if (_type == 3) {
            data = arr.filter(item => item.type == _type && item.hoodId == pid)
        }
        let datas = data.map(item => {
            let { type, id, officeName, hoodName, buildingName } = item
            let title = buildingName || hoodName || officeName
            let type_, children = []
            if(type == 1) {
                type_ = 2
                children = util.treeFns(arr, type_, id, { expand } )
            } else if (type == 2) {
                type_ = 3
                children = util.treeFns(arr, type_, id, { expand } )
            } else if (type == 3) {
                children = []
            }
            return {
                title,
                label: title,
                value: id,
                type,
                node: item,
                expand: expand,
                children
            }
        })
        return datas
    },

    // 表格日期格式化( 字符串截取xxxx-xx-xx)
    tableDate(date) {
        if (date == null) {
            return ''
        } else {
            return date.substr(0, 10)
        }
    },


    // 手动生成Dom结构
    create(target, props, inner) {
        const dom = document.createElement(target)
        if (props) {
            if (typeof props == 'object') {
                if (props.class) {
                    dom.className = props.class
                }
                if (props.style) {
                    for (let key in props.style) {
                        dom.style[key] = dom.style[key]
                    }
                }
                if (props.on) {
                    for (let key in props.on) {
                        dom['on' + key] = props.on[key]
                    }
                }
                util.append(dom, inner)
            } else {
                util.append(dom, props)
            }
        }
        return dom
    },
    append(dom, inner) {
        if (inner) {
            if (typeof inner == 'object') {
                if (Array.isArray(inner)) {
                    inner.map(function (item) {
                        return dom.appendChild(item)
                    })
                } else {
                    dom.appendChild(inner)
                }
            } else if (inner) {
                dom.innerHTML = inner
            }
        }
    },

    // JSON转Form表单
    toFormData(params) {
        const formData = new FormData()
        Object.keys(params).forEach((key) => {
            formData.append(key, params[key]);
        });
        return formData
    },

    // 删除''和[]的参数
    delNullParams(params) {
        Object.keys(params).forEach(name => {
            if (params[name] === '') delete params[name]
            if (JSON.stringify(params[name]) === '[]' ) delete params[name]
            if(typeof params[name] === 'object' && params[name] !== null) util.delNullParams(params[name])
        })
    },

    // 将参数值为"","[]", undefined的参数设置参数值为null
    setNullParams(params) {
        Object.keys(params).forEach(name => {
            if(params[name] == undefined || params[name] == "" || JSON.stringify(params[name]) === '[]') {
                params[name] = null
            }
            // 参数类型为对象
            if(typeof params[name] === 'object' && params[name] !== null && Array.isArray(params[name]) === false) {
                util.setNullParams(params[name])
            }
        })
    },

    // 转gpx文件
    creatGPX(line_pos, lineName) {
        // 承接gpx文件字符串
        let lineGPX = `<?xml version="1.0" encoding="UTF-8"?>\n<gpx creator="StravaGPX" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd http://www.garmin.com/xmlschemas/GpxExtensions/v3 http://www.garmin.com/xmlschemas/GpxExtensionsv3.xsd http://www.garmin.com/xmlschemas/TrackPointExtension/v1 http://www.garmin.com/xmlschemas/TrackPointExtensionv1.xsd" version="1.1" xmlns="http://www.topografix.com/GPX/1/1" xmlns:gpxtpx="http://www.garmin.com/xmlschemas/TrackPointExtension/v1" xmlns:gpxx="http://www.garmin.com/xmlschemas/GpxExtensions/v3">\n`
                    + `<metadata>\n<name>${lineName}</name>\n<time>${line_pos[0].time}</time></metadata>\n<trk>\n<name>${lineName}</name>\n<type>1</type>\n<trkseg>\n`
        line_pos.forEach((item) => {
            lineGPX += `<trkpt lat="${item.lat}" lon="${item.lon}">\n<ele>${item.ele}</ele>\n<time>${item.time}</time></trkpt>\n`
        });
        lineGPX += `</trkseg>\n</trk>\n</gpx>\n`
        return lineGPX
    },

    // 获取gpx文件的数据
    getGPXNode(gpxStr) {
        let data = []
        let gpxDom = new DOMParser().parseFromString(gpxStr, "text/xml");
        let trkpt = gpxDom.getElementsByTagName('trkpt')
        let ele = gpxDom.getElementsByTagName('ele')
        let time = gpxDom.getElementsByTagName('time')
        let len = trkpt.length || 0
        if(len) {
            for(let i = 0; i < len; i++) {
                data.push({
                    lng: trkpt[i].attributes.lon.nodeValue,
                    lat: trkpt[i].attributes.lat.nodeValue,
                    ele: ele[i].innerHTML == 'undefined' || 'null' ? '50' : ele[i].innerHTML,
                    time: time[i].innerHTML
                })
            }
        }
        return data
    },

      // html剔除富文本标签，留下纯文本
    getSimpleText(html) {
        let text = ''
        if (html) {
        let regExp = new RegExp("<.+?>", "g");//匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
        text = html.replace(regExp, '').replace(/&nbsp;/ig, '');//执行替换成空字符

        }
        return text;
    },

    // 设置颜色十六进制转换成rgb
    set16ToRgb(str, flag = false) {
        var reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
        if(!reg.test(str)){return;}
        let newStr = (str.toLowerCase()).replace(/\#/g,'')
        let len = newStr.length;
        if(len == 3){
            let t = ''
            for(var i=0;i<len;i++){
                t += newStr.slice(i,i+1).concat(newStr.slice(i,i+1))
            }
            newStr = t
        }
        let arr = []; //将字符串分隔，两个两个的分隔
        for(var i =0;i<6;i=i+2){
            let s = newStr.slice(i,i+2)
            arr.push(parseInt("0x" + s))
        }
        if(flag) {
            return arr
        } else {
            return 'rgb(' + arr.join(",")  + ')';
        }
     },

    //  设置颜色rgb转换成十六进制
    setRgbTo16(str){
        let reg = /^(rgb|RGB)/;
        if(!reg.test(str)){return;}
        var arr = str.slice(4, str.length-1).split(",")
        let color = '#';
        for(var i=0;i<arr.length;i++){
             var t = Number(arr[i]).toString(16)
             if(t == "0"){   //如果为“0”的话，需要补0操作,否则只有5位数
                 t =  t + "0"
             }
             color += t;
        }
        return color;
    },

    // // 设置hex
    // convertToHex(data) {
    //     let hue = data['hue'] / 360
    //     let saturation = data['saturation'] / 100
    //     let x = parseInt(data['x'] * 255)
    //     let y = parseInt(data['y'] * 255)
    //     let hue_hex = format(parseInt(hue * 255), '02x')
    //     let saturation_hex = format(parseInt(saturation * 255), '02x')
    //     let x_hex = format(x, '02x')
    //     let y_hex = format(y, '02x')

    //     let hex_value = '#' + hue_hex + saturation_hex + x_hex + y_hex
    //     return hex_value
    // },
    // // 将HSL颜色值转换为RGB颜色值
    // hslToRgb(h, s, l) {
    //     h /= 360;
    //     s /= 100;
    //     l /= 100;
    
    //     let r, g, b;
    
    //     if (s === 0) {
    //     r = g = b = l;
    //     } else {
    //     const hue2rgb = (p, q, t) => {
    //         if (t < 0) t += 1;
    //         if (t > 1) t -= 1;
    //         if (t < 1 / 6) return p + (q - p) * 6 * t;
    //         if (t < 1 / 2) return q;
    //         if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    //         return p;
    //     };
    
    //     const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    //     const p = 2 * l - q;
    
    //     r = hue2rgb(p, q, h + 1 / 3);
    //     g = hue2rgb(p, q, h);
    //     b = hue2rgb(p, q, h - 1 / 3);
    //     }
    
    //     return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    // },
  
    // // 将RGB颜色值转换为十六进制颜色值
    // rgbToHex(r, g, b) {
    //     const componentToHex = (c) => {
    //     const hex = c.toString(16);
    //     return hex.length === 1 ? "0" + hex : hex;
    //     };
    
    //     return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    // }
  
    //   const hslColor = { hue: 25, saturation: 95, x: 0.5267, y: 0.4133 };
    
    //   const rgbColor = hslToRgb(hslColor.hue, hslColor.saturation, hslColor.x);
    //   const hexColor = rgbToHex(rgbColor[0], rgbColor[1], rgbColor[2]);
    
    //   console.log(hexColor); // 输出：#f7a800
    
     


}

export default util