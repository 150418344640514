export default {

    AMapLevel: { 1: 'province', 2: 'city', 3: 'district' },
    // // 季节
    // season: { 1: '春季', 2: '夏季', 3: '秋季', 4: '冬季', 5: '四季皆宜' },
    // // 学段
    // applicablePhases: { 1: '幼儿', 2: '小学', 3: '初中', 4: '高中', 5: '大学' },
    // // 学科
    // subject: { 1: '语文', 2: '数学', 3: '英语', 4: '政治', 5: '历史', 6: '地理', 7: '物理', 8: '化学', 9: '生物', 10: '科学', 11:'美术', 12: '音乐', 13: '信息', 14: '综合' },
    // 季节
    season: { '春季': '春季', '夏季': '夏季', '秋季': '秋季', '冬季': '冬季', '四季皆宜': '四季皆宜' },
    // 学段
    applicablePhases: { '幼儿': '幼儿', '小学': '小学', '初中': '初中', '高中': '高中', '大学': '大学' },
    // 学科
    subject: { '语文': '语文', '数学': '数学', '英语': '英语', '政治': '政治', '历史': '历史', '地理': '地理', '物理': '物理', '化学': '化学', '生物': '生物', '科学': '科学', '美术':'美术', '音乐': '音乐', '信息': '信息', '综合': '综合' },
    // 是否公开
    isPublic: { 1: '是', 0: '否' },
    // 文件夹ID
    folderId: {
        'point': 2162,    // 研学点
        'line': 2163,     // 研学线
        'course': 2164,   // 研学课程
        'activity': 2165, // 研学活动
        'image': 2166,    // 研学图片
        'video': 2167,    // 研学视频
        'docx': 2168,     // 研学文档
        'img360': 2169,   // 研学全景
        'wenJuan': 2170,  // 研学问卷
        'trace': 2171,    // 研学轨迹
        'data': 2173,     // 研学数据
    },
    // 全景类型
    roamtype: { 'point': '研学点', 'line': '研学路线', 'panorama': '研学全景' },

    // 活动资源类型
    resourceType: { 'pan/text': '文本', "pan/gpx": "轨迹", "pan/image": "图片", "pan/epub": "课程" },

    // 灯光特效
    // blink breathe okay channel_change finish_effect stop_effect
    effect: {
        'blink': '闪烁',
        'breathe': '呼吸',
        'okay': '常亮',
        'channel_change': 'channel_change',
        'finish_effect': 'finish_effect',
        'stop_effect': 'stop_effect',
    },

    colorTempMove: {
        move: 'move',
        stop: 'stop'
    },

    // 设备类型
    deviceType: {
        "TS0503B": "灯带",
        "TS0505B": '投光灯',
        "TS0601": '窗帘',
        "TS0011": "单键开关",
        "TS0013": "三键开关",
        "TS011F": '插座',
        "TS004F": "旋钮",
        "TS1201": "红外",
        "TS0222": "温度计",
        "TS0044": '随意贴',
        "ti.router": "中继网关",


    }



}