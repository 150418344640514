<template>
    <DatePicker :value="current" :format="format" :type="type" @on-change="handlerSelect" :size="size" :placeholder="placeholder" :clearable="clearable" :disabled="disabled" :transfer="transfer" class="w-100"></DatePicker>
</template>
<script>
export default {
    props: {
        type: { type: String, default: 'daterange' },
        value: { type: Object, required: true },
        keys: { type: String, required: true },
        size: String,
        format: {
            type: String, default() {
                return this.type == 'daterange' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss'
            }
        },
        dayEnd: { type: Boolean, default: false },
        placeholder: { type: String, default: '开始时间-结束时间' },
        clearable: { Boolean, default: true },
        disabled: Boolean,
        transfer:Boolean,
    },
    computed: {
        current() {
            const obj = this.value
            const [start, end] = this.keys.split('-')
            return [obj[start], obj[end]]
        }
    },
    methods: {
        handlerSelect(val) {
            const [start, end] = this.keys.split('-')
            this.$set(this.value, start, this.type == 'daterange' && this.dayEnd && val[0] ? val[0] + ' 00:00:00' : val[0])
            this.$set(this.value, end, this.type == 'daterange' && this.dayEnd && val[1] ? val[1] + ' 23:59:59' : val[1])

        }
    }
}
</script>

