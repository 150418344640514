import store from './index'
import {
  _api,
  util
} from '@/libs'
import MqttServer from '@/libs/mqttServer'
import iView from 'view-design'

store.registerModule('client', {
  namespaced: true,
  state: {
    mqttServer: null,
    client: null,
    classroomId: null,
    classroomState: false,
    groups: [],
    groupScenes: [],
    groupIds: {},
    members: {},
    devices: [],
    thermometer: null,
    deviceInfo: {},
    isClient: false,
    tvInfo: {},
    deviceNameById: {},
    devicesByType: [],
  },
  getters: {
    client: state => state.client,
    members: state => state.members,
  },
  mutations: {
    SET_MQTT_SERVER(state, mqttServer) {
      console.log("state", state)
      state.mqttServer = mqttServer
      state.client = mqttServer.client
      if (state.client) {
        store.commit('client/SET_IS_CLIENT', true)
      }
    },
    SET_CLASSROOM_ID(state, classroomId) {
      state.classroomId = classroomId
      localStorage.setItem("classroomId", classroomId)
    },
    SET_DEVICES(state, devices) {
      state.devices = devices
      store.commit('client/SET_DEVICES_BY_TYPE', devices)
      store.commit('client/SET_DEVICE_NAME_BY_ID', devices)
    },
    // 获取有效设备
    SET_DEVICES_BY_TYPE(state, devices) {
      const typeShow = ["TS0503B", "TS0505B", "TS0601", "TS0011", "TS0013"]
      let data = []
      if (devices.length) {
        data = devices.filter(item => (item => typeShow.includes(item.model_id)))
      }
      state.devicesByType = data
    },
    // 根据设备ID获取name 返回{id: 设备名}
    SET_DEVICE_NAME_BY_ID(state, devices) {
      let data = {}
      if (devices.length) {
        devices.map(item => data[item.ieee_address] = item.friendly_name)
      }
      state.deviceNameById = data
    },
    SET_GROUPS(state, groups) {
      state.groups = groups
      let scenes = []
      if (groups.length) {
        groups.map(item => {
          if (item.scenes.length) {
            item.scenes.map(node => {
              scenes.push({
                ...node,
                groupId: item.friendly_name,
              })

            })
          }
        })
      }
      store.commit('client/SET_GROUP_SCENES', scenes)
    },
    SET_GROUP_SCENES(state, groupScenes) {
      state.groupScenes = groupScenes
    },
    SET_CLASSROOM_STATE(state, classroomState) {
      state.classroomState = classroomState
    },
    SET_GROUP_IDS(state, {
      groupId,
      payload
    }) {
      state.groupIds[groupId] = payload
    },
    SET_MEMBERS(state, {
      key,
      payload
    }) {
      state.members[key] = payload
      console.log("SET_MEMBERS state.members:", state.members)
    },
    SET_TV_INFO(state, {
      key,
      payload
    }) {
      state.tvInfo[key] = payload
    },
    SET_IS_CLIENT(state, isClient) {
      state.isClient = isClient
    },
    SET_THERMOMETER(state, thermometer) {
      state.thermometer = thermometer
    },
  },
  actions: {

    // 订阅消息
    onSubscribe({
      state
    }, topic) {
      state.client.subscribe(topic)
    },
    // 发布消息
    onPublish({
      state
    }, {
      topic,
      params = {},
      qos = {
        qos: 0
      }
    }) {
      const paramsStr = JSON.stringify(params)
      console.log("onPublish topic:", topic, paramsStr)
      if (state.client) {
        state.client.publish(topic, paramsStr, qos)
      }
    },
    // 获取监听消息
    getClientMessage({
      state,
      commit
    }) {
      state.client.on('message', (topic, message) => {

        console.log(`${topic}: ${message.toString()}`);
        // 获取设备列表
        if (topic.includes("/bridge/devices")) {
          const devices = JSON.parse(message.toString());
          commit('SET_DEVICES', devices)
        }

        // 获取群组信息
        if (topic.includes("/groups")) {
          const groups = JSON.parse(message.toString());
          commit('SET_GROUPS', groups);
        }

        // 教室获取在线状态
        if (topic.includes(`${state.classroomId}/bridge/state`)) {
          commit('SET_CLASSROOM_STATE', message.toString())
        }
        // 获取群组在线状态
        if (topic.includes("/availability")) {
          let groupId = topic.split("/")[2];
          commit('SET_GROUP_IDS', {
            groupId,
            payload: message.toString()
          })
        }

        // 监听设备、群组的配置参数
        const topicArr = topic.split('/')
        const key = topicArr[topicArr.length - 1]
        if (topicArr.length == 3) {
          commit('SET_MEMBERS', {
            key,
            payload: JSON.parse(message.toString())
          })
        }

        //  获取温度计详情
        if (topic.includes("温度计")) {
          const thermometer = JSON.parse(message.toString());
          thermometer.updateTime = Date.now()
          commit('SET_THERMOMETER', thermometer)
        }

        // 获取TV配置参数
        if (topic.includes('/tv') && topicArr.length == 4) {
          commit('SET_TV_INFO', {
            key,
            payload: JSON.parse(message.toString())
          })
          console.log(JSON.parse(message.toString()))
        }

        // 健康状态检查
        if (topic.includes('/bridge/response/health_check')) {
          const res = JSON.parse(message, toString())
          // {"data":{"healthy":true},"status":"ok"}
          iView.Notice[res.status == 'ok' ? 'success' : 'error']({
            title: '健康状态检查情况',
            desc: res.status == 'ok' ? `教室网络连接健康状态正常！` : '教室网络连接异常，请稍后再试！',
          })
        }
      })
      state.client.on("close", () => {
        commit('SET_IS_CLIENT', false)
      })
    },

    // 健康状态检查
    healthCheck({
      state,
      dispatch
    }) {
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/health_check`
      })
    },

    // 协调器（⽹关）检查
    coordinatorCheck({
      state,
      dispatch
    }) {
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/coordinator_check`
      })
    },

    // 服务重启
    reStart({
      state,
      dispatch
    }) {
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/restart`
      })
    },

    // 允许新设备加入
    permitJoin({
      state,
      dispatch
    }, params) {
      // params : { "value": true | false, "time" : 20 //允许加⼊多少秒，可选 }
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/permit_join`,
        params
      })
    },

    // 重命名设备
    renameDevice({
      state,
      dispatch
    }, params) {
      // params: { "from": deviceID, "to": deviceID }
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/device/rename`,
        params: params
      })
    },

    // 强制设备进⼊重新配对状态
    deviceConfigure({
      state,
      dispatch
    }, deviceId) {
      // params:{"id": "id | ieee addr"}
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/device/configure`,
        params: {
          id: deviceId
        }
      })
    },

    // 删除设备
    delDevice({
      state,
      dispatch
    }, deviceId) {
      // params参数可配置
      // params =  block":false //禁⽌再次加⼊（可选）, "force":false //强制删除（可选}
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/device/remove`,
        params: {
          id: deviceId
        }
      })
    },

    // 创建群组
    addGroup({
      state,
      dispatch
    }, params) {
      // params: {"friendly_name": NAME, "id": num} // name 与 id 全教室唯⼀
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/group/add`,
        params: params
      })
    },

    // 重命名群组
    renameGroup({
      state,
      dispatch
    }, params) {
      // params: {"from": name | id, "to": name}
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/group/rename`,
        params: params
      })
    },

    // 删除群组
    delGroup({
      state,
      dispatch
    }, groupId) {
      // params: {"id": "id | name","force":false //是否强制删除，可选}
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/group/remove`,
        params: {
          id: groupId
        }
      })
    },

    // 添加群组设备
    addGroupMember({
      state,
      dispatch
    }, {
      group,
      device
    }) {
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/group/members/add`,
        params: {
          group,
          device
        }
      })
    },

    // 删除群组设备
    delGroupDevice({
      state,
      dispatch
    }, {
      group,
      device
    }) {
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/group/members/remove`,
        params: {
          group,
          device
        }
      })
    },
    // 将设备从所有群组中删除
    delAllGroupMember({
      state,
      dispatch
    }, {
      device
    }) {
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/request/group/members/remove_all`,
        params: {
          device
        }
      })
    },

    // 添加群组场景
    addGroupScene({
      state,
      dispatch
    }, {
      topic,
      params
    }) {
      dispatch('onPublish', {
        topic,
        params
      })
    },
    // 重命名群组场景
    renameGroupScene({
      state,
      dispatch
    }, {
      topic,
      params
    }) {
      dispatch('onPublish', {
        topic,
        params
      })
    },

    // 召唤群组场景
    callGroupScene({
      state,
      dispatch
    }, {
      topic,
      params
    }) {
      dispatch('onPublish', {
        topic,
        params
      })
    },
    // 删除群组场景
    delGroupScene({
      state,
      dispatch
    }, {
      topic,
      params
    }) {
      dispatch('onPublish', {
        topic,
        params
      })
    },

    // 事件监听
    eventListeners({
      state,
      commit
    }, params) {
      dispatch('onPublish', {
        topic: `zigbee/${state.classroomId}/bridge/event`,
        params: {}
      })
      // response:{
      // "type":"device_joined | device_interview | device_leave | device_announce",
      // "data":device_info
      // }
    }
  },

})