import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './modules'
import '@/components'
// 引入vant
import vant from 'vant'
// 引入组件样式
import 'vant/lib/index.css'

import moment from 'moment'
// 设置本地时区
moment.locale('zh-cn')
Vue.prototype.$moment = moment


/*引入jq*/
import $ from "jquery"
window.$ = $
window.jQuery = $

import iView from 'view-design'
Vue.use(iView)
import './style/re-iview.less'

import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(VueViewer)


import './modules/ivew'
import './modules/user'
import './modules/client'


import './style/index.less'
// import '../public/static/font/iconfont.css'/


Vue.config.productionTip = false

Vue.use(vant)

new Vue({ router, store, render: h => h(App) }).$mount('#app')
