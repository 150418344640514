import { request } from '@/libs'

/** 
 * 研学路线API
 */

// 查找用户所创建全部研学线
export function getLineAll() {
    return request.get('/yanxue-app/research/line/all')
}

// 模糊查询所有已公开的研学线
export function getAllLineEntity(params) {
    return request.post(`/yanxue-app/research/line/getallentity/namelike?name=${params.name}`, params)
}

// 根据线id获取研学线上所拥有的的研学点
export function getPointsByLineId(params) {
    return request.get('/yanxue-app/research/line/points?line_id=' + params.researchLineId)
}

// 分页查询
export function getLine(params) {
    // return request.post(`/yanxue-app/research/line/findbypage?id=${params.userId}`, params)
    return request({
        url: '/yanxue-app/research/line/findbypage',
        method: 'post',
        data: params,
        params: {
            id: params.userId
        }
    })
}

// 根据研id获取详情
export function getLineById(params) {
    return request.get('/yanxue-app/research/line/one', { params })
}

// 添加
export function addLine(params) {
    return request.post('/yanxue-app/research/line/add', params)
}

// 编辑
export function updateLine(params) {
    return request.put(`/yanxue-app/research/line/update?id=${params.researchLineId}`, params)
}

// 删除
export function deleteLine(params) {
    return request.delete('/yanxue-app/research/line/delete', { params })
}
